$color_1: rgba(57, 57, 70, 0.4);
$background-color_1: #eff7fd;

.dashboard-footer-info {
  background-color: $background-color_1;
  margin: 0px 10px;
  border-radius: 20px 20px 0px 0px;
  padding: 53px 0px 20px;
  .inside_footer__content {
    width: 100%;
    text-align: center;
    .footer-widget-1 {
      padding-right: 20px;
      .widget_media_image {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 25px;
      }
      .widget_custom_html {
        > p {
          width: 100%;
          max-width: 500px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3;
          color: #393946;
          margin: 0 auto 15px;
        }
      }
    }
  }
  .footer-bar-list {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      list-style: inside;
      color: #393946;
      li {
        &:first-child{
          list-style: none;
        }

        a {
          color: #393946;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          padding-right: 15px;
          position: relative;
        }
      }
    }
  }
  .inside-site-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(217, 217, 217, 0.3);
    padding-top: 20px;
    margin-top: 40px;
  }
  .widget_media_image {
    img {
      max-width: 290px;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-footer-info {
    .inside_footer__content{
      .footer-widget-1{
        .widget_custom_html{
          & > p{
            font-size: 14px;
            color: $color_1;
          }
        }
      }
    }
    .inside-site-info {
      flex-flow: column;
      padding-top: 15px;      
      margin-top: 15px;
      .copyright-bar {
        order: 2;
        padding-top: 15px;
      }
      .footer-bar-list {
        order: 1;
      }
    }
    .footer-bar-list {
      ul {
        justify-content: center;
        flex-wrap: wrap;
        opacity: .4;
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
