// Home Page RTL SCSS

.theme_dir__rtl {
  .navigation-links {
    .link-list {
      li {
        &:last-child {
          a {
            margin-right: 0px;
            margin-left: 30px;
          }
        }
      }
    }
  }
  .login-btn {
    img {
      margin-right: 0px;
      margin-left: 8px;
    }
  }
  .navigation-menu {
    .header-lang-drop {
      li {
        margin-right: 15px;
        margin-left: 40px;
      }
    }
  }
  .popup {
    left: 27%;
    right: auto;
  }
  .tool-inner-slider {
    .toll-box-inner {
      .tool-box {
        .box-upper {
          .box-body {
            .box-content {
              img {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
  .modern-work {
    .modern-work-info {
      .right-content {
        img.display_img_block {
          display: none;
        }
        img.display_img_none {
          display: block;
        }
      }
    }
  }
  .about-box-content {
    a {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
.modern-work {
  .modern-work-info {
    .right-content {
      img.display_img_block {
        display: block;
      }
      img.display_img_none {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 767px) and (max-width: 1440px) {
  .theme_dir__rtl {
    .popup {
      left: 4%;
      right: auto !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .popup {
      left: 0px;
      right: 0px;
    }
  }
  .file-convert-list-main {
    .convert-formate-main {
      .convert-to-main {
        .convert-close {
          img {
            top: 48px;
            right: auto;
            left: 7px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .theme_dir__rtl {
    .navigation-menu {
      .header-lang-drop {
        li {
          margin-right: 15px;
          margin-left: 10px;
        }
      }
    }
  }
}

// Contact Us Page RTL SCSS

// Pricing Page RTL SCSS

.theme_dir__rtl {
  .pricing-content {
    .pricing-content-icon {
      img {
        padding-right: 0px;
        padding-left: 10px;
      }
    }
  }
  .pricing-box-2 {
    margin-right: 0px;
    margin-left: 30px;
  }
  .pricing-box-1 {
    margin-right: 0px;
    margin-left: 30px;
  }
}

// Unsubscribe Page RTL SCSS

.theme_dir__rtl {
  .unsubscribe-section {
    .text-content {
      .submit-btn {
        button {
          svg {
            margin-right: 0px;
            margin-left: 5px;
          }
        }
        right: auto;
        left: 4px;
      }
    }
  }
}

// PopUp  RTL SCSS
