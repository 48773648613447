.account_bg_wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 90px;
  margin-bottom: 30px;
  @media only screen and (max-width: 1400px) {
    padding: 30px 75px;
  }
  @media only screen and (max-width: 1200px) {
    padding: 30px 60px;
  }
  @media only screen and (max-width: 991px) {
    padding: 30px;
  }
  @media only screen and (max-width: 767px) {
    padding: 15px;
  }
}

.account_title {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  h2 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    border-bottom: 1px solid rgb(217 217 217 / 70%);
    margin: 0px 0px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.wpb_content_account {
  .account_info_cmt {
    display: flex;
  }
  .account_info_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .wi-50 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  .account_address {
    strong {
      width: 100%;
      font-family: Roboto;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.1;
      color: #444444;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
      a,
      time {
        width: 100%;
        font-family: Roboto;
        display: flex;
        align-items: center;
        column-gap: 150px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #596171;
      }
      .myaccount_time {
        cursor: default;
      }
    }
  }
}

//subscription css

.subscriptions_table {
  font-family: Roboto;
  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #444444;
        padding: 15px 7px 5px;
        text-align: left;

        span {
          font-weight: 400;
        }

        &::before {
          content: attr(data-title);
          font-size: 0px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.1;
          color: #333333;
        }

        .tracking_btn {
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #ffffff;
          border-radius: 3px;
          background-color: #1664fa;
          border: 1px solid #1664fa;
          padding: 5px 6px;
          outline: none;
          box-sizing: unset;
          margin-left: 8px;

          &.more_report_btn {
            background-color: #008000;
            border: none;
          }
        }

        img {
          width: 100%;
          max-width: 20px;
          height: 100%;
          max-height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        .cancel_btn {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          color: #24537b;
          background-color: transparent;
          outline: none;
          box-shadow: unset;
          border: 0px;
          padding: 0px 0px;
          text-align: left;
          cursor: pointer;
        }
      }

      td.cancel_subscription {
        text-align: center;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid rgb(217 217 217 / 50%);

        th {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.1;
          color: #333333;
          text-align: left;
          padding: 7px 7px 14px;
        }
      }
    }
  }
}

.theme_dir__rtl {
  .subscriptions_table {
    table {
      tbody,
      thead {
        td,
        th {
          text-align: right;

          .tracking_btn {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.error-message {
  color: red;
  margin-top: 2px;
}

.required-field {
  color: red;
}

.red-bg {
  background-color: #ffd6d6;
  color: #e8505b;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 11px;
}

.green-bg {
  background-color: #c5f8dc;
  color: #44b678;
  padding: 6px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.green-text {
  color: #44b678;
  font-size: 12px;
}

.mixed-status {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.default-status {
  color: #444444;
}

@media only screen and (max-width: 767px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          width: 100%;
          padding: 15px 15px;
          display: block;
          border-radius: 5px;
          border: 1px dashed#24537B;
          background: #ffffff;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          td {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 50% 50%);
            align-items: center;

            &::before {
              font-size: 14px;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgb(217 217 217 / 50%);
            }
          }
        }
      }

      thead {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 435px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          padding: 5px 5px;

          td {
            align-items: unset;
            grid-template-columns: repeat(1, 40% 60%);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          td {
            .tracking_btn {
              margin-left: 0px;
              display: block;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_title {
    h2 {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .subscriptions_table {
    table {
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        td {
          span {
            font-weight: 500;
          }

          span.green-bg {
            width: max-content;
          }

          span.red-bg {
            width: max-content;
          }
        }

        > tr {
          border: 1px dashed #24537b !important;
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 0px;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 875px) {
  .subscriptions_table {
    table {
      tbody {
        > tr {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .subscriptions_table {
    table {
      tbody {
        td {
          span.mixed-status {
            flex-wrap: wrap;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .subscriptions_table {
    table {
      tbody {
        td {
          padding: 15px 7px 15px;

          span {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          td {
            &:last-child {
              grid-template-columns: repeat(1, 100% 100%);
            }
          }
          td.cancel_subscription {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .wpb_content_subs {
    padding: 1.25rem;
  }
}

@media only screen and (max-width: 425px) {
  .subscriptions_table {
    table {
      tbody {
        td {
          > span {
            flex-wrap: wrap;

            > span {
              width: 100%;
            }

            button.tracking_btn {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

// account subscription

@media only screen and (max-width: 991px) {
  .account_title {
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
  .wpb_content_account {
    .account_address {
      p {
        a {
          column-gap: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_bg_wrap {
    padding: 15px 15px;
  }
  .account_title {
    h2 {
      font-size: 20px;
    }
  }
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: nowrap;
      justify-content: space-between;
      .wi-50 {
        max-width: max-content;
        flex: 0 0 max-content;
      }
    }
    .account_address {
      strong {
        width: auto;
      }
      p {
        width: auto;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: wrap;
      row-gap: 20px;
      .wi-50 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .account_address {
        &:first-child {
          border-bottom: 1px solid rgb(119 119 119 / 10%);
          padding-bottom: 20px;
        }
      }
    }
  }
}

// Billing Information Css

.account-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;

  .field-account.address {
    grid-column: 1 / 3;
  }

  label.lbl_wpb_wrapper {
    width: 100%;
    font-family: Roboto;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    color: #777777;
    margin-bottom: 10px;
  }

  .field-account {
    .errors {
      margin: 0;
      color: red;
    }
    input[type='text'],
    input[type='number'],
    select {
      width: 100%;
      border-radius: 5px;
      font-family: Roboto;
      border: 1px solid #dfeaee;
      background: rgba(236, 247, 251, 0.5);
      padding: 16px 13px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      outline: none;
      color: #9ea8bd;
      -webkit-appearance: none;

      &::placeholder {
        color: #9ea8bd;
      }
    }

    input[type='number'] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px 0px;
      }
    }
  }
}

button.payment__account {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 9px 15px;
  color: #ffffff;
  background-color: #0076ce;
  border: 1px solid #0076ce;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: transparent;
    border: 1px solid #0076ce;
    color: #0076ce;
  }
}

@media only screen and (max-width: 625px) {
  .account-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
    .field-account.address {
      grid-column: 1 / 1;
    }
    .field-account {
      input[type='text'],
      input[type='number'] {
        padding: 13px 13px;
        font-size: 14px;
      }
    }
  }
}

// Subscriptions Css
.wpb_info_wrapper {
  padding: 29px 0px 20px;
}
.subscriptions_info_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #444;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  button {
    color: #24537b;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: unset;
  }
}
@media only screen and (max-width: 475px) {
  .subscriptions_info_wrap {
    padding: 20px 25px;
    border-radius: 5px;
    border: 1px dashed #24537b;
    background: #fff;
  }
}
@media only screen and (max-width: 425px) {
  .subscriptions_info_wrap {
    padding: 15px 5px;
    border-radius: 5px;
    border: 1px dashed #24537b;
    background: #fff;
    span {
      font-size: 13px;
    }
    button {
      font-size: 13px;
    }
  }
}
