/* ==============================================
== How Work Section Css Start
============================================== */

.file-icons-inner-small {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    height: 20px !important;
    width: 20px !important;
  }
  span {
    font-size: 15px;
    text-transform: uppercase;
  }
}

.tool-work-section {
  .tool-work-box-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
    column-gap: 60px;

    .tool-work-box {
      max-width: calc(33.33% - 40px);
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
      padding: 25px;
      height: auto;
      display: flex;
      flex-direction: column;

      .tool-box-head-content {
        h4 {
          color: #2d2d2f;
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 24px;
          @media screen and (max-width: 991.5px) and (min-width: 767.5px){
            font-size: 18px;
          }
          @media screen and (max-width: 767.5px){
            font-size: 20px;
          }
        }
      }

      .tool-box-icon-wrap {
        border-radius: 10px;
        border: 1px dashed rgba(0, 118, 206, 0.3);
        background: rgba(200, 231, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 30px;
        margin-top: auto;

        .tool-box-icon-info {
          .imgs {
            width: 100%;
            max-width: 68px;
          }

          .popup{
            top: 0;
            transform: unset;
            right: -100px;
          }
        }

        .tool-box-icon-info.tool-box-icon-text {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          gap: 6px;
          .drop-button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            gap: 20px;
            font-size: 20px;
            color: #393946;
            font-family: 'Inter', sans-serif;
            font-weight: bold;
            padding: 14px 24px;
            border-radius: 10px;
            border: 1px solid rgba(0, 118, 206, 0.25);
            background: #ffffff;
            cursor: pointer;

            @media screen and (max-width: 991.5px) and (min-width: 767.5px){
              padding: 8px 12px;
              gap: 10px;
              font-size: 16px;
            }

            @media screen and (max-width: 575px){
              gap: 10px;
              padding: 12px 20px;
            }

            @media screen and (max-width: 440px){
              font-size: 14px;
              padding: 10px 10px;
            }
            @media screen and (max-width: 360px){
              font-size: 12px;
              padding: 10px 6px;
              gap: 8px
            }

            img {
              height: 16px;
              width: 16px;
            }
          }
          .tool-span {
            color: #808080;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
          }
        }
      }

      .tool-box-button {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-radius: 34px;
          background: #0076ce;
          border: 1px solid #0076ce;
          width: 100%;
          padding: 13px;
          margin-top: 23px;
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (min-width: 767.5px) and (max-width: 1440px) {
  .tool-box-icon-info .popup{
    top: 0;
    right: -130px !important;
  }
}

.convert-info-wrap {
  .tool-about-box-wrap {
    padding-bottom: 0px;
  }
}

.tool-work-section.blue-design-pattern {
  position: relative;
  z-index: 11;
  padding-bottom: 0px;
  margin-bottom: 150px;

  &:before {
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: calc(100% - 135px);
    top: 0;
    bottom: -1px;
    left: 10px;
    right: 10px;
    background-color: #eff7fd;
    z-index: -1;
    border-radius: 20px;
    background-image: url('../../../../assets/images/home/line-design-pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .main_container{
    padding: 0 30px;
  }
}

@media screen and (max-width: 1326px) {
  .tool-work-section {
    .tool-work-box-wrap {
      column-gap: 40px;

      .tool-work-box {
        max-width: calc(50% - 20px);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tool-work-section.blue-design-pattern:before {
    height: calc(100% - 500px);
  }

  .tool-work-section.blue-design-pattern {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .tool-work-section.blue-design-pattern {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 991px) {
  .tool-work-section {
    &.blue-design-pattern{
      margin-bottom: 100px;
    }
    .common-title{
      max-width: calc(78% + 25px);
      margin: 0 auto;
    }
    .tool-work-box-wrap {
      column-gap: 25px;
      row-gap: 25px;

      .tool-work-box {
        max-width: 39%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tool-work-section {
    &.blue-design-pattern:before {
      width: 100%;
      left: 0;
    }
    .common-title{
      max-width: 100%;
      margin: 0 auto;
    }
    .tool-work-box-wrap {
      .tool-work-box {
        max-width: 100%;

        .tool-box-head-content {
          h4 {
           margin-bottom: 15px;
          }
        }

        .tool-box-button {
          button {
            font-size: 15px;
            padding: 10px;
          }
        }
      }
    }
  }

  .tool-work-section.blue-design-pattern {
    margin-top: 0px;
    margin-bottom: 50px;
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 766px){
  .tool-work-section.blue-design-pattern:before {
    height: calc(100% - 720px);
  }
}
@media only screen and (max-width:652px){
  .tool-work-section.blue-design-pattern:before {
    height: calc(100% - 870px);
  }
}
@media only screen and (max-width:380px){
  .tool-work-section.blue-design-pattern:before {
    height: calc(100% - 900px);
  }
}
@media screen and (max-width: 575px) {
  .tool-work-section {
    .tool-work-box-wrap {
      .tool-work-box {
        padding: 25px;

        .tool-box-head-content {
          h4 {
          }
        }

        .tool-box-icon-wrap {
          gap: 20px;
          padding: 20px;

          @media screen and (max-width: 360px){
            gap: 10px
          }

          .tool-box-icon-info {
            & > img {
              min-width: 15px;
              max-width: 50px;
              width: 100%;
            }
          }
        }

        .tool-box-button {
          button {
            font-size: 15px;
            padding: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
}

/* ==============================================
== How Work Section Css End
============================================== */
