$color_1: #393946;
$color_2: #868686;
$color_3: #1b5f92;
$color_4: #0076ce;

.contact-main-section {
  padding: 100px 0px;
}
.contact-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.contact-left-content {
  h2 {
    font-size: 42px;
    font-weight: 900;
    color: $color_1;
    margin-bottom: 30px;
  }
  span {
    font-size: 24px;
    font-weight: 400;
    color: $color_1;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: $color_1;
    line-height: 24px;
    a {
      color: $color_1;
    }
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-right-content {
  width: 50%;
  text-align: center;
}
@media only screen and (max-width: 1199px) {
  .contact-main-section {
    padding: 80px 0px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-left-content {
    h2 {
      font-size: 38px;
      margin-bottom: 20px;
    }
    span {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .contact-wrapper {
    display: inline-block;
  }
  .contact-left-content {
    width: 100%;
    text-align: center;

    p {
      font-size: 16px;
      color: $color_2;
      a{
        color: inherit;
      }
    }
  }
  .contact-right-content {
    display: none;
  }
  .contact-image {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .contact-left-content {
    h2 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    span {
      font-size: 18px;
      margin-bottom: 15px;
    }
    
  }
  .contact-us .main_container {
    padding: 0px 22px;
  }
  @media only screen and (max-width: 475px) {
    .contact-main-section {
      padding: 50px 0;
    }
    .contact-left-content h2 {
      margin-bottom: 20px;
    }
  }
}
