$color_1: rgba(57, 57, 70, 0.4);
$color_2: #2d2d2f;
$color_3: #393946;
$color_4: #0076ce;
$color_5: rgba(0, 118, 206, 1);
$background-color_1: #eff7fd;

/*=============================================
= Footer Css Start
=============================================*/
/***********CopyRight Footer Css***********/
/*=============================================
= Footer Css End
=============================================*/
.widget_media_image {
  img {
    max-width: 290px;
    width: 100%;
    height: 100%;
    display: block;
  }
}
.site__footer {
  background-color: $background-color_1;
  margin: 0px 10px;
  border-radius: 20px 20px 0px 0px;
  padding: 60px 0px 15px;
  .inside_footer__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .footer-widget-1 {
      max-width: 50%;
      flex: 0 0 50%;
      padding-right: 20px;
      .widget_media_image {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 25px;
      }
      .widget_custom_html {
        > p {
          width: 100%;
          max-width: 350px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3;
          color: $color_1;
          margin-bottom: 15px;
        }
        .footer-contact-wrap {
          > p {
            margin-bottom: 0px;
          }
          p {
            a {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.3;
              color: $color_1;
              margin-bottom: 15px;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cg clip-path='url(%23clip0_775_3240)'%3E%3Cpath d='M9.92073 10.4156C9.49779 10.6976 9.00651 10.8466 8.5 10.8466C7.99352 10.8466 7.50225 10.6976 7.0793 10.4156L0.113189 5.77139C0.0747337 5.74567 0.0369897 5.71891 0 5.69113L0 13.3012C0 14.1737 0.708057 14.8662 1.56496 14.8662H15.435C16.3075 14.8662 17 14.1581 17 13.3012V5.6911C16.9629 5.71895 16.9251 5.74577 16.8865 5.77152L9.92073 10.4156Z' fill='%23393946' fill-opacity='0.4'/%3E%3Cpath d='M0.665723 4.94257L7.63184 9.58682C7.89554 9.76263 8.19775 9.85052 8.49997 9.85052C8.80222 9.85052 9.10446 9.7626 9.36816 9.58682L16.3343 4.94257C16.7511 4.66482 17 4.19998 17 3.69828C17 2.83563 16.2982 2.13385 15.4356 2.13385H1.56443C0.701815 2.13388 8.23472e-07 2.83566 8.23472e-07 3.69911C-0.000257782 3.94522 0.0603975 4.18756 0.176557 4.40452C0.292716 4.62149 0.460768 4.80633 0.665723 4.94257Z' fill='%23393946' fill-opacity='0.4'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_775_3240'%3E%3Crect width='17' height='17' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: left 3px;
              padding-left: 27px;
            }
          }
        }
      }
    }
    .footer-widget-2 {
      max-width: 16.66%;
      flex: 0 0 16.66%;
      padding-right: 20px;
    }
    .footer-widget-3 {
      max-width: 16.66%;
      flex: 0 0 16.66%;
      padding-right: 20px;
    }
    .footer-widget-4 {
      max-width: 16.66%;
      flex: 0 0 16.66%;
      padding-right: 20px;
    }
    h2.resources-widget-title {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color_2;
      margin-bottom: 20px;
    }
  }
}
.menu-footer-resources-menu {
  ul.menu-item-type-post_type {
    li {
      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.4;
        color: $color_3;
        margin-bottom: 15px;
        transition: all 0.4s ease-in-out;
        position: relative;
      }
      &:hover {
        a {
          color: $color_4;
        }
      }
      &:last-child {
        a {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.inside-site-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(217 217 217 / 30%);
  padding-top: 20px;
  margin-top: 40px;
  .copyright-bar {
    p {
      width: 100%;
      display: block;
      vertical-align: baseline;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: $color_3;
      opacity: 0.8;
      line-height: normal;
      @media only screen and (max-width: 767px) {
        opacity: .7;
      }
      a {
        display: inline-block;
        vertical-align: baseline;
        color: $color_3;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .site__footer {
    .inside_footer__content {
      .footer-widget-1 {
        padding-right: 12px;
      }
      .footer-widget-2 {
        padding-right: 12px;
      }
      .footer-widget-3 {
        padding-right: 12px;
      }
      .footer-widget-4 {
        padding-right: 12px;
      }
    }
  }
}
@media screen and (max-width: 875px) {
  .site__footer {
    .inside_footer__content {
      .footer-widget-1 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
        .widget_custom_html {
          > p {
            margin: 0px 0px 15px;
          }
        }
      }
      .footer-widget-2 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
      }
      .footer-widget-3 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
      }
      .footer-widget-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
      }
    }
  }
}
@media screen and (max-width: 676px) {
  .site__footer {
    padding: 40px 0px 15px;
    margin: 0px 0px;
  }
}
@media screen and (max-width: 575px) {
  .site__footer {
    .inside_footer__content {
      .footer-widget-2 {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
      .footer-widget-3 {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
      .footer-widget-4 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
    }
  }
  .inside-site-info {
    border-top: unset;
    padding-top: 0px;
    margin-top: 0px;
    .copyright-bar {
      p {
        justify-content: center;
        margin-bottom: 10px;
      }
      width: 100%;
      text-align: center;
    }
    .footer-bar {
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 375px) {
  .site__footer {
    .inside_footer__content {
      .footer-widget-2 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
      .footer-widget-3 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
      .footer-widget-4 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(217 217 217 / 30%);
      }
    }
  }
}

// Menu Animation Css

.menu-footer-resources-menu {
  ul.menu-item-type-post_type {
    li {
      a {
        &:before {
          content: '';
          position: absolute;
          top: auto;
          bottom: -5px;
          right: auto;
          left: 0px;
          width: 0%;
          height: 2px;
          background-color: #0076ce;
          transition: all 0.5s ease-in-out;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}
