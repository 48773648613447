.hide_convert_popup {
  display: none !important;
}

.file-convert-modal {
  max-width: 740px;
  width: 100% !important;
  max-height: 95vh;
  margin: auto;
  overflow: auto;
  border-radius: 12px;
  background-color: #fff;

  &::-webkit-scrollbar{
    width: 8px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb{
    background-color:rgba(0, 0, 0, .3);
    border-radius: 50px;
  }
  .ant-modal-content {
    padding: 0 !important;
    box-shadow: unset;

    button[aria-label='Close'] {
      top: 30px;
      right: 30px !important;
    }

    .banner-section {
      padding: 0 !important;
      .main_container {
        padding: 30px;
      }
      .banner-content {
        margin-bottom: 20px;
        h1 {
          color: rgba(57, 57, 70, 0.8);
          font-size: 24px;
          font-weight: 700;
          text-align: left;
          margin-bottom: 0 !important;
        }
        p:empty {
          display: none;
        }
      }

      .drag-drop-inner {
        box-shadow: unset !important;
        border: 0 !important;

        .drag-drop-element {
          height: 100% !important;
          margin-bottom: 15px;
          &:before {
            inset: 0;
            width: 100%;
            height: 100%;
            background: #f0f9ff !important;
            border-color: rgba(0, 118, 206, 0.4) !important;
          }

          .drop-area {
            padding: 0 !important;
            label {
              height: 215px;
              justify-content: center !important;
              color: #393946;
              font-size: 18px;
              font-weight: 600;

              &::before {
                margin-top: 0;
              }
              &::after {
                margin-bottom: 0;
              }
            }
          }
        }

        .banner-device-element {
          padding: 0 !important   ;
          .device-icon-inner {
            border-color: #a1d7ff !important;
            background-color: transparent !important;
            gap: 10px !important;

            &:hover {
              background: #f0f9ff !important;
            }

            &:before {
              display: none !important;
            }

            img {
              max-width: 32px !important;
            }
          }
        }

        .file-convert-list-main {
          padding: 20px 0 25px !important;
          .your-file.single_line {
            display: none;
          }
          .file-convert-list-main {
            padding: 0 !important;

            .wpb_content-wrapper {
              padding: 21px 20px 21px 24px !important;
              border-radius: 10px !important;
              border: 1px solid rgba(0, 172, 234, 0.3) !important;
              background: #eff7fd !important;

              &:last-child {
                margin-bottom: 0;
              }

              .ant-progress {
                margin-top: 0;
              }

              .file-converting-main{
                .file-convert-img{
                  width: fit-content !important;
                  margin-right: 20px;
                }
              }

              .file-actions {
                .convert-to-main {
                  .convert-formate {
                    & > span:first-child {
                      color: #0076ce;
                      font-size: 16px;
                    }
                    .convert-option {
                      span {
                        color: #393946;
                        font-size: 14px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        &:before {
                          content: '';
                          width: 24px;
                          height: 24px;
                          display: block;
                          background-image: url('/assets/images/dashboard/convertIcon.svg');
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: contain;
                        }
                      }
                    }
                  }
                }
              }

              .custom-select-popup {
                .popup {
                  right: -50px;
                  transform: translate(0);
                }
              }
            }
          }
        }
        .wpb_convert-btn{
          button{
            img{
              filter: invert(100) brightness(100);
              width: 20px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .file-convert-modal {
    .ant-modal-content {
      button[aria-label='Close'] {
        z-index: 1;
        top: 15px;
        right: 15px !important;
      }

      .banner-section {
        .main_container {
          padding: 15px;
        }

        .banner-content {
          margin-bottom: 13px;
          h1 {
            font-size: 18px;
          }
        }

        .drag-drop-inner {
          .drag-drop-element {
            .drop-area {
              label {
                height: 167px;
                &::before {
                  display: none;
                }
              }
            }
          }

          .file-convert-list-main {
            .file-convert-list-main {
              .wpb_content-wrapper {
                padding: 15px 20px 8px !important;

                .file-actions {
                  .convert-to-main {
                    .convert-formate {
                      .convert-option {
                        div {
                          min-width: 115px;
                          padding: 4px 0px;
                          gap: 4px;
                          justify-content: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.5px) {
  .file-convert-second-modal {
    .convert-formate-main {
      flex-direction: row !important;

      .file-actions {
        width: fit-content !important;
      }
    }
  }
}

.hide-upload-modal {
  display: none !important;
}

@supports (selector(:has(*))){
  div:has(+ div > div > div > .file-convert-second-modal){
    display: none ;
  }
}