@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ant-modal.thankyou-info-wrap {
  .ant-modal-content {
    padding: 0px 20px 20px;
  }
}

.thankyou-info-wrap {
  .ant-modal-content {
    margin: 0px 0px !important;
  }
  .ant-modal-footer{
    display: none;
  }
}

.thankyou-modal-section{
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  .thankyou-modal-title{
    display: inline-block;
    vertical-align: top;
    margin: 0px auto;
    text-align: center;
    span {
      display: inline-block;
      vertical-align: top;
      color: #FFFFFF;
      font-family: 'Inter';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 0px 0px 10px 10px;
      background: #0076ce;
      padding: 18px 23px;
      @media only screen and (max-width: 767px){
        font-size: 18px;
      }
    }
  }
}
.thankyou-modal-section {
  .wrap-top-modal {
    margin-top: 50px;
    @media only screen and (max-width: 767px){
      margin-top: 30px;
    }
    .body-modal {
      .modal-thankyou-form {
        .progress-container{
          display: flex;
          justify-content: center;
        }
        position: relative;
        margin-bottom: 40px !important;
        @media only screen and (max-width: 767px){
          margin-top: 25px !important;
        }
        label {
          .ant-form-item-required {
            opacity: 0.6;
            color: rgba(0, 0, 0, 0.88);
            font-family: 'Inter';
            font-size: 13.6px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.2px;
          }
        }
        .ant-form-item {
          .ant-col {
            input {
              width: 100%;
              height: 49px;
            }
          }
        }
      }
      .submit-btn-modal {
        width: 100%;
        justify-content: center !important;
        display: flex;
        .hl_cta_wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: 'Roboto';
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3;
          height: 100%;
          padding: 8px 20px;
          border: 1px solid #0076ce;
          border-radius: 42px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: #0076ce;
          color: #ffffff;
          cursor: pointer;
          &:hover{
            background-color: transparent;
            color: #0076CE;
            img{
              filter: brightness(0) saturate(100%) invert(27%) sepia(86%) saturate(3302%) hue-rotate(190deg) brightness(95%) contrast(101%);
            }
          }
          .arrow-icon {
            width: 20px;
            height: 16px;
            margin-left: 10px;
            transition: 0.5s ease-in-out;
          }
        }
        .hl_border_button {
          background-color: transparent;
          color: #0076CE;
          img{
            filter: brightness(0) saturate(100%) invert(27%) sepia(86%) saturate(3302%) hue-rotate(190deg) brightness(95%) contrast(101%);
          }
          &:hover{
            background-color: #0076ce;
            color: #ffffff;
            img{
              filter: brightness(1);
            }
          }
         }
      }
      .submit-mb{
        margin-bottom: 15px;
      }
      .description-modal {
        width: 100%;
        max-width: 405px;
        margin: 0px auto 30px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        color: #393946;
        font-family: 'Inter';
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
.auth-divider {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  .line {
    flex: 1 1;
    height: 1px;
    background-color: #D9D9D9;
  }
}
@media screen and (max-width: 576px) {
  .ant-modal.thankyou-info-wrap {
    .ant-modal-content {
      padding: 0px 20px 15px;
      margin: 0px 20px;
    }
  }
}
@media screen and (max-width: 425px) {
  .ant-modal.thankyou-info-wrap {
    .ant-modal-content {
      margin: 0px 10px;
    }
  }
  .thankyou-modal-section {
    .wrap-top-modal {
      .body-modal {
        .submit-btn-modal {
          .hl_cta_wrap {
            font-size: 15px;
          }
        }
      }
      .google-button-thankyou {
        .thankyou-bg-wrap {
          font-size: 17px;
        }
      }
    }
  }
  .auth-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .thankyou-modal-title {
    span {
      font-size: 18px;
    }
  }
}
.success_progress {
  margin-left: 5%;
}
.mb {
  margin-left: 35%;
  margin-bottom: 40px;
  width: 380px;
  height: 100px;
}

// Progress Animation Scss
.thankyou-modal-section {
  .wrap-top-modal {
    .body-modal {
      .modal-thankyou-form {
        svg.ant-progress-circle {
          circle.ant-progress-circle-trail {
            stroke-width: 2px !important;
          }
          circle.ant-progress-circle-path {
            stroke-width: 5px !important;
            stroke: rgb(0, 186, 0) !important;
          }
        }
        span.ant-progress-text {
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #000000;
          font-family: 'Roboto';
        }
      }
    }
  }
}
