.mobile-menu-toggle-list.dashboard-header-menu {
  max-width: 180px;
  width: 100%;

  li.ant-dropdown-menu-item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(135, 135, 135, 0.2);
      border-radius: 0px;
      padding: 0px 20px;
    }

    &:last-child {
      padding: 0px 20px 15px 20px;
    }
    &:first-child {
      padding: 10px 20px 0px 20px;
    }
  }

  span.ant-dropdown-menu-title-content {
    padding: 20px 0px 15px 0px;
  }

  ul.ant-dropdown-menu.ant-dropdown-menu-root {
    padding: 0px;
  }

  .mobile-menu-text {
    a {
      color: #878787;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      padding-right: 13px;
      margin-right: 22px;
      position: relative;
    }
  }
}
.dashboard-header-menu-icon {
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.NotificationDropDownArea {
  width: calc(300px - 20px) !important;
  max-height: 320px !important;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-height: 300px;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  .NotificationSpinner {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 300px;
    width: 100%;
    max-width: calc(304px - 20px) !important;
  }
  .NotificationDropDownBox {
    display: flex;
    transition: all 0.4s ease-in-out;
    padding: 6px 8px;
    align-items: end;
    margin-bottom: 5px;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0px;
    }
    .wpb__NotificationDropDown {
      max-width: 70%;
      flex: 0 0 70%;
      .wpb__NotificationCenter {
        h5 {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          color: #000000;
          line-height: 1.4;
          margin: 0px 0px 4px;
        }
        p {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          color: #262626;
          line-height: 1.2;
          margin: 0px 0px;
        }
      }
    }
    .unread_NotificatiobWrapper {
      max-width: 30%;
      flex: 0 0 30%;
    }
    &:hover {
      background-color: #e5f3ff;
      border-radius: 5px;
    }
  }
}
.NotificationCenterWrap {
  cursor: pointer;
  img.ant-dropdown-trigger {
    width: 100%;
  }
}
@media only screen and (max-width: 475px) {
  .AntNotificationDropDownArea {
    inset: 60px 10px auto auto !important;
  }
}

.header_wrap_area {
  .header_functionality {
    .NotificationCenterWrap {
      position: relative;
      span {
        font-size: 8px;
        font-weight: 600;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #24537b;
        border-radius: 100%;
        top: -1px;
        bottom: auto;
        right: -3px;
        left: auto;
      }
    }
  }
}
.notificationAddressinfo {
  min-width: unset !important;
}

.theme_dir__rtl {
  .NotificationDropDownArea {
    .NotificationDropDownBox {
      .unread_NotificatiobWrapper {
        text-align: left !important;
      }
      .wpb__NotificationDropDown {
        .wpb__NotificationCenter {
          h5 {
            direction: ltr;
            text-align: right;
          }
        }
      }
    }
  }
}
