@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
$color_1: #393946;
$color_2: rgba(135, 135, 135, 0.4);
$color_3: #fff;
$color_4: #0076ce;
$color_5: rgb(57 57 70 / 50%);
$color_6: rgb(57 57 70 / 30%);
$color_7: red;
$color_8: #00acea;
$color_9: #00acea;
$color_10: #181d20;
$font-family_1: Inter;
$font-family_2: Roboto;
$font-family_3: 'Inter', sans-serif;
$font-family_4: 'Inter';
$background-color_1: #eff7fd;
$background-color_2: rgba(0, 172, 234, 0.1);
$background-color_3: #efedfd;
$background-color_4: #fef2eb;
$background-color_5: #edf4fd;
$background-color_6: #eff9fb;
$background-color_7: #e5e1fd;
$background-color_8: #ffdfcd;
$background-color_9: #e0edfe;
$background-color_10: #d6f7fe;

/******** Table Css ********/
/*=============================================
= Dashboard End
=============================================*/
/* ==============================================
== File Converter Css Start
============================================== */
/* ==============================================
== File Converter Css End
============================================== */
/* ==============================================
== Work Type Css Start
============================================== */
/* ==============================================
== Work Type Css End
============================================== */
section.your_documents_sec {
  padding: 120px 0 0px;

  .ant-table-wrapper {
    margin-top: 50px;

    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
}

.main_row {
  display: flex;
  align-items: center;
}

.main_col.title_dock1 {
  margin-right: auto;
}

.section_title {
  h1 {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

.file_search {
  position: relative;
  margin-right: 20px;

  @media only screen and (max-width: 767px) {
    margin-right: 0;
  }

  input.form-control {
    &::placeholder {
      color: $color_2 !important;
    }
  }
}

input.form-control {
  background: $color_3;
  border: 0;
  border-bottom: 1px solid #cacaca;
  font-size: 14px;
  height: 45px;
  letter-spacing: 0.01rem;
  line-height: 25px;
  margin-bottom: 0 !important;
  margin-left: 0;
  margin-top: 0 !important;
  padding-left: 5px;
  padding-right: 26px;
  outline: none;
  width: 245px;
}

.search-img {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 7px;
  top: 6px;
}

.upload_btn {
  span {
    color: $color_3;
    font-family: $font-family_2;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    border-radius: 42px;
    background: $color_4;
    padding: 11px 30px;
    border: 1px solid $color_4;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    display: inline-block;

    &:hover {
      background: transparent;
      color: $color_4;

      img {
        filter: brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(4204%) hue-rotate(188deg) brightness(89%) contrast(101%);
      }
    }

    img {
      margin-right: 8px;
      vertical-align: top;
      width: 18px;
      height: 18px;
      transition: all 0.3s ease 0s;
    }
  }
}

// upload-files*****************

.upload-files-drop-box {
  max-width: 195px;
  width: 100%;
  height: 199px;

  .upload-files-menu {
    display: flex;
    align-items: center;
    justify-content: normal;
    gap: 16px;
    padding-left: 16px;

    span {
      color: #6b6b6b;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  ul.ant-dropdown-menu.ant-dropdown-menu-root {
    padding: 12px 12px 8px 12px;

    &:hover {
      background-color: #ffffff;
    }
  }

  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    padding: 0px 0px 4px 0px;

    span.ant-dropdown-menu-title-content {
      background-color: #eaf0fb;
      padding: 10px 0px 10px;
      border-radius: 5px;
    }
  }
}

// upload-option*****************

.upload-files-option {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 22px;
  }

  span {
    color: #737373;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.dashboard-option-menu {
  max-width: 170px;
  width: 100%;
  height: 104px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

  ul.ant-dropdown-menu.ant-dropdown-menu-root {
    padding: 0px;
  }

  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    &:last-child {
      padding: 10px 12px 12px;
    }

    &:first-child {
      padding: 12px 12px 10px;
    }

    span.ant-dropdown-menu-title-content {
      padding: 0px;
    }
  }
}

// table-css*********************************************************************
.ant-table-wrapper {
  &.document-table-info {
    th.ant-table-cell {
      color: rgba(57, 57, 70, 0.5);
      font-family: $font-family_1;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background-color: $color_3;

      &::before {
        content: unset;
      }

      span.ant-checkbox-inner::after {
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        border-collapse: collapse;
        border: 2px solid rgb(0, 172, 234);
        border-top: 0;
        border-inline-start: 0;
        width: 5.7142857143px;
        height: 9.1428571429px;
        background: $color_3;
        top: 50%;
        inset-inline-start: 25%;
        display: table;
        box-sizing: border-box;
        position: absolute;
      }
    }

    span.ant-checkbox-inner {
      background-color: transparent;
      border-color: rgba(0, 172, 234, 1);
    }

    .ant-table-content {
      table {
        border-collapse: separate;
        border-spacing: 0 15px;

        tbody {
          tr {
            td {
              border-top: 1px solid rgba(57, 57, 70, 0.1);
              border-bottom: 1px solid rgba(57, 57, 70, 0.1);

              &:first-child {
                border-left: 1px solid rgba(57, 57, 70, 0.1);
                border-radius: 10px 0 0 10px;
                padding-right: 0;
              }

              &:last-child {
                border-right: 1px solid rgba(57, 57, 70, 0.1);
                border-radius: 0 10px 10px 0;
                padding-right: 15px;
              }
            }
          }

          tr.ant-table-row-selected {
            td {
              border-top: 1px solid rgba(0, 172, 234, 0.3);
              border-bottom: 1px solid rgba(0, 172, 234, 0.3);

              &:first-child {
                border-left: 1px solid rgba(0, 172, 234, 0.3);
                border-radius: 10px 0 0 10px;
              }

              &:last-child {
                border-right: 1px solid rgba(0, 172, 234, 0.3);
                border-radius: 0 10px 10px 0;
              }
            }

            span.ant-wave-target {
              overflow: hidden;
            }

            span.ant-checkbox-inner {
              background-color: transparent;
              border-color: rgba(0, 172, 234, 1);
            }

            ::after {
              border-collapse: collapse;
              border: 2px solid rgba(0, 172, 234, 1);
              border-top: 0;
              border-inline-start: 0;
              width: 5.7142857142857135px;
              height: 9.142857142857142px;
              top: 50%;
              inset-inline-start: 25%;
              display: table;
              box-sizing: border-box;
              position: absolute;
            }
          }
        }
      }
    }

    ul {
      justify-content: center;
      align-items: center;
      margin: 16px 0px 0px;

      li.ant-pagination-item-active {
        a {
          color: $color_4;
        }
      }
    }

    td.ant-table-cell.ant-table-cell-row-hover {
      background-color: #e6f4ff !important;
    }
  }

  .ant-pagination {
    justify-content: center !important;
    align-items: center;

    li {
      height: 100%;

      &:not(:last-child, .ant-pagination-prev, .ant-pagination-next) {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #e9e9e9;
        background: #f7f7f7;

        @media only screen and (max-width: 440.5px) {
          width: 30px;
          height: 30px;
        }

        &.ant-pagination-jump-next {
          a {
            display: block;
            width: 100%;
            padding-bottom: 10px;

            span {
              display: block;
            }
          }
        }

        a {
          color: #808080;
          font-size: 16px;
          font-weight: 500;
          padding: 0;

          @media only screen and (max-width: 440.5px) {
            font-size: 14px;
          }
        }
      }

      &.ant-pagination-item-active {
        border: 1px solid #0076CE;
        background: #FFF;

        a {
          color: #0076CE;
        }
      }
    }
  }
}

.document-default-btn.convert-btn {
  border: 0.5px solid $color_4;
  background: rgba(0, 118, 206, 0.07);
  color: $color_4;
  padding: 8px 30px !important;
}

.document-default-btn.download-btn {
  border: 0.5px solid #27c056;
  background: #f0fbf3;
  color: #27c056;
}

.document-default-btn.delete-btn {
  border: 0.5px solid #fe475a;
  background: #fff2f3;
  color: #fe475a;
  padding: 8px 41px;
}

.file-type {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #0076ce;
  font-size: 14px;
  gap: 20px;
}

.formate-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .convert-to-btn {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    appearance: none;
    background: #fff;
    border: 1px solid #0076ce40;
    border-radius: 5px;
    color: #393946;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    min-width: 129px;
    outline: #ffdead;
    padding: 8px 10px;
    width: 100%;

    @media only screen and (max-width: 440px) {
      min-width: unset;
    }

    span {
      font-family: Inter, sans-serif;
      font-style: normal;
      line-height: normal;
    }
  }
}

.ant-table-content {
  .ant-table-tbody {
    .disabled-row {
      // opacity: 0.5;
      // pointer-events: none;

      td {
        border-color: #ffb1b9 !important;

        &:nth-child(3),
        &:nth-child(5) {
          color: #393946;
        }

        @media only screen and (max-width: 767px) {
          &:nth-child(4) {
            color: #393946;
          }
        }

        .ant-checkbox-wrapper {
          opacity: 0;
        }
      }

      &.expiry_row {
        td {
          border-color: #ffb1b9 !important;

          &:nth-child(3),
          &:nth-child(5) {
            text-decoration: line-through;
          }

          @media only screen and (max-width: 767px) {
            &:nth-child(4) {
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}

.disabled-row:hover::after {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.ant-modal.modal-content.urL-modal {
  width: 661px !important;
}

button.download-file-btn {
  border: 1px solid #bfdcf2;
  background: rgba(33, 146, 239, 0.1);
  color: #0076ce;
  height: 32px;
  min-width: 32px;
  border-radius: 5px;
}

.delete-file-btn {
  background-color: transparent;
  border: none;
  min-width: 32px;
  cursor: pointer;
}

.delete-file-btn-modal {
  background-color: transparent;
  border: none;
}

.document-default-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 30px;
  font-family: $font-family_4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
}

.document-table-info {
  td.ant-table-cell {
    color: $color_1;
    font-family: $font-family_4;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 15px;

    .ant-checkbox-wrapper {
      overflow: hidden;

      &:after {
        opacity: 0;
      }
    }
  }
}

.document-table-info .ant-checkbox-wrapper:after {
  opacity: 0;
}

.document-table-info :where(.css-dev-only-do-not-override-98ntnt).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: transparent;
  border-color: rgb(0, 172, 234);
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-98ntnt).ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: transparent;
  border: 1px solid #4096ff;
}

// end table-css*******************************

.dock_info {
  display: flex;
  align-items: center;

  .dock_inner {
    &:not(:last-of-type) {
      padding-right: 10px;
    }
  }
}

.dock_inner {
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
  }
}

.error-message {
  color: $color_7;
  margin-top: 2px;
}

.required-field {
  color: $color_7;
}

section.dash_banner-section {
  background-color: $background-color_1;
  margin: 0 20px;
  border-radius: 30px;
}

.dash_banner-section {
  padding: 130px 0px 150px;
}

section.work_type_sec {
  padding: 85px 0px 100px;

  .common-title {
    h2 {
      text-align: center;

      @media screen and (min-width: 1200px) {
        font-size: 60px;
        margin-bottom: 60px;
      }
    }
  }
}


.work_box_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.work_box {
  margin: 15px;
  border-radius: 5px;
}

.work_box1 {
  background-color: #efedfd;

  .work_icon {
    background-color: #e5e1fd;
  }
}

.work_box2 {
  background-color: #fef4ee;

  .work_icon {
    background-color: #ffdfcd;
  }
}

.work_box3 {
  background-color: #edf4fd;

  .work_icon {
    background-color: #e0edfe;
  }
}

.work_box4 {
  background-color: #e5fff3;

  .work_icon {
    background-color: #d5f5e6;
  }
}

.work_box5 {
  background-color: #e6fbff;

  .work_icon {
    background-color: #d6f7fe;
  }
}

.work_box6 {
  background-color: rgba(237, 255, 226, 0.9);

  .work_icon {
    background-color: #dff3d2;
  }
}

.work_box7 {
  background-color: #fff6fe;

  .work_icon {
    background-color: rgba(255, 234, 253, 1);
  }
}

.work_box8 {
  background-color: #fff9eb;

  .work_icon {
    background-color: #fff2d0;
  }
}

.work_box9 {
  background-color: #fbf1ff;

  .work_icon {
    background-color: #f6dfff;
  }
}

.work_box10 {
  background-color: #f8ffe3;

  .work_icon {
    background-color: #ecf4d2;
  }
}

.work_box11 {
  background-color: #fff0ed;

  .work_icon {
    background-color: rgba(246, 222, 218, 1);
  }
}

.work_box12 {
  background-color: #fffbd2;

  .work_icon {
    background-color: rgba(245, 239, 170, 1);
  }
}

.work_detl {
  display: flex;
  align-items: center;
  padding: 20px;

  p {
    padding-left: 15px;
    padding-right: 15px;
    color: $color_10;
    font-family: $font-family_1;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.work_icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  img {
    max-height: 45px;
    object-fit: cover;
  }
}

.work_box5 {
  .work_icon {
    background-color: $background-color_10;
  }
}

.work_arrow {
  margin-left: auto;
}

@media screen and (max-width: 1400px) {
  .documents_table {
    table {
      tbody {
        td {
          &:nth-child(3) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  section.your_documents_sec {
    padding: 120px 0px 130px;
  }

  section.work_type_sec {
    padding: 0px 0px 120px;
  }
}

@media screen and (max-width: 1199px) {
  section.your_documents_sec {
    padding: 100px 0px;
  }

  .section_title {
    h1 {
      font-size: 38px;
    }
  }

  .documents_table {
    table {
      tbody {
        td {
          &:nth-child(3) {
            font-size: 14px;
          }
        }
      }
    }
  }

  section.work_type_sec {
    padding: 0px 0px 100px;
  }
}

@media screen and (max-width: 1024px) {
  section.your_documents_sec {
    padding: 80px 0px;
  }

  .section_title {
    h1 {
      font-size: 34px;
    }
  }

  section.work_type_sec {
    padding: 0px 0px 80px;
  }

  .work_box_main {
    grid-template-columns: repeat(2, 1fr);
  }

  .work_detl {
    p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 991px) {
  section.your_documents_sec {
    padding: 60px 0px;
  }

  .section_title {
    h1 {
      font-size: 30px;
    }
  }

  .documents_table {
    padding-top: 40px;

    table {
      thead {
        tr {
          grid-template-columns: repeat(1, 40px 81px 1fr 170px 60px 380px);
        }
      }

      tbody {
        tr {
          grid-template-columns: repeat(1, 40px 81px 1fr 170px 60px 380px);
        }
      }
    }
  }

  section.work_type_sec {
    padding: 0px 0px 60px;
  }

  .work_icon {
    width: 60px;
    height: 60px;

    img {
      max-height: 35px;
    }
  }

  .work_detl {
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 890px) {
  .documents_table {
    table {
      thead {
        tr {
          grid-template-columns: repeat(1, 35px 60px 165px 164px 44px 360px);
        }
      }

      tbody {
        tr {
          grid-template-columns: repeat(1, 35px 60px 165px 164px 44px 360px);
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .documents_table {
    table {
      thead {
        tr {
          grid-template-columns: repeat(1, 35px 60px 1fr 112px 53px 360px);

          th {
            padding: 20px 6px;
          }
        }
      }

      tbody {
        tr {
          grid-template-columns: repeat(1, 35px 60px 1fr 112px 53px 360px);

          td {
            padding: 20px 6px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .documents_table {
    table {
      thead {
        tr {
          display: grid;
          grid-template-columns: repeat(1, 40px 90px 1fr 200px 80px 380px);
          width: 100%;
          align-items: center;
        }
      }

      tbody {
        tr {
          display: grid;
          grid-template-columns: repeat(1, 40px 90px 1fr 200px 80px 380px);
          width: 100%;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  section.your_documents_sec {
    padding: 50px 0px;
  }

  .section_title {
    h1 {
      font-size: 26px;
    }
  }

  .main_row {
    flex-wrap: wrap;
  }

  .main_col.title_dock1 {
    order: 1;
    width: 56%;
  }

  .main_col.title_dock2 {
    order: 3;
    width: 100%;
  }

  .main_col.title_dock3 {
    order: 2;
    width: 44%;
  }

  .search_bar {
    padding-top: 20px;
  }

  .upload_btn {
    text-align: right;

    a {
      font-size: 16px;
    }
  }

  input.form-control {
    width: 100%;
  }

  .documents_table {
    padding-top: 30px;

    table {
      tbody {
        tr {
          width: 100%;
          padding: 15px 15px;
          display: block;
          border-radius: 5px;
          border: 1px solid rgb(0 172 234 / 30%);
          background: rgba(236, 247, 251, 0.3);

          &:not(:last-child) {
            margin-bottom: 20px;
            grid-template-columns: unset;
          }

          td {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 33% 68%);
            align-items: center;
            padding: 10px;

            &::before {
              font-size: 14px;
              color: $color_6;
            }

            &:first-child {
              grid-template-columns: unset;
              padding-bottom: 43px;
              padding-top: 0;
            }

            &:not(:last-child) {
              border-bottom: 1px dashed rgba(217, 217, 217, 0.5);
              border-radius: 0;
            }
          }
        }

        td {
          label {
            &::before {
              top: 0;
              left: 0;
              transform: unset;
            }

            &::after {
              top: 0;
              left: 0;
              transform: unset;
            }
          }
        }
      }

      thead {
        tr {
          th {
            &:not(:first-of-type) {
              display: none;
            }
          }
        }

        th {
          label {
            &::before {
              top: 0;
              left: 0;
              transform: unset;
              left: 40%;
            }

            &::after {
              top: 0;
              left: 0;
              transform: unset;
              left: 40%;
            }
          }
        }
      }
    }
  }

  .dock_info {
    justify-content: right;
  }

  .dock_inner {
    &:first-of-type {
      margin-right: auto;
    }

    &:not(:first-of-type) {
      span {
        display: none;
      }
    }
  }

  .banner-bg-img {
    display: none;
  }

  .dash_banner-section {
    background-image: url('../../assets/images/home/mobile-hero-banner-pattern.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 0px 60px;
  }

  section.work_type_sec {
    padding: 0px 0px 50px;
  }

  .work_detl {
    padding: 15px;

    p {
      font-size: 16px;
    }
  }

  .work_icon {
    width: 50px;
    height: 50px;

    img {
      max-height: 28px;
    }
  }


  .download-file-btn {
    display: none;
  }

  .delete-file-btn {
    display: none;
  }

  .your_documents_sec .ant-table-tbody .ant-table-cell {
    position: relative;
    padding: 12px 5px;
  }

  .your_documents_sec thead.ant-table-thead tr th:nth-child(2),
  .your_documents_sec thead.ant-table-thead tr th:nth-child(3),
  .your_documents_sec thead.ant-table-thead tr th:nth-child(4),
  .your_documents_sec thead.ant-table-thead tr th:nth-child(5) {
    display: none;
  }

  .your_documents_sec .ant-table-thead tr th:nth-child(6) {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 99;
    transform: translateX(-50%);
    border-radius: 4px 4px 0px 0px;
    background: #fff !important;
    box-shadow: 0px -11px 12px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .your_documents_sec tr.ant-table-row td:nth-child(2) img {
    width: 100%;
    max-width: 30px;
    display: block;
  }

}

@media screen and (max-width: 620px) {
  .work_box_main {
    grid-template-columns: repeat(1, 1fr);
  }

  .work_box {
    margin: 10px 0;
  }

  .work_arrow {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.document-table-info {
  td.ant-table-cell {
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {
  .section_title {
    h1 {
      font-size: 22px;
    }
  }

  .upload_btn {
    span {
      padding: 10px 25px;
      font-size: 16px;
      vertical-align: middle;

      img {
        vertical-align: middle;
      }
    }
  }
}

@media screen and (max-width: 465px) {
  .main_col.title_dock1 {
    width: 55%;
  }

  .section_title {
    h1 {
      font-size: 20px;
    }
  }

  .main_col.title_dock3 {
    width: 42%;
  }

  .upload_btn {
    span {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 5px;
      }
    }
  }

  .document-default-btn {
    gap: 5px;
    padding: 9px 20px;
  }

  .document-default-btn.delete-btn {
    padding: 9px 20px;
  }

  .your_documents_sec {
    .main_container {
      padding: 0px 10px;
    }
  }

  .document-table-info .ant-table-cell {
    >div {
      gap: 4px !important;
    }
  }
}

@media screen and (max-width: 435px) {
  .documents_table {
    table {
      tbody {
        tr {
          td {
            align-items: unset;
            grid-template-columns: repeat(1, 40% 60%);

            .tracking_btn {
              margin-left: 0px;
              display: block;
              margin-top: 8px;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .upload_btn {
    span {
      font-size: 14px;
    }
  }
}

.more-option {
  display: none;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}



.ant-table-content {
  .ant-table-thead {
    tr {
      align-items: center;

      th {
        background-color: unset !important;
        color: rgba(57, 57, 70, 0.80) !important;

        @media only screen and (min-width: 767px) {
          height: 100%;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border-top: 1px solid rgba(57, 57, 70, .1);
        border-bottom: 1px solid rgba(57, 57, 70, .1);
        background-color: #fff;
        margin-top: 15px;

        &:first-child {
          border-radius: 10px 0 0 10px;
          border-left: 1px solid rgba(57, 57, 70, .1);
        }

        &:last-child {
          border-radius: 0 10px 10px 0;
          border-right: 1px solid rgba(57, 57, 70, .1);

          &>div {
            gap: 0 !important;
          }
        }
      }

      &.ant-table-row-selected {
        td {
          border-color: #DEF3FF;
          background-color: #F7FCFF;
        }
      }
    }
  }

  tr {
    @media only screen and (min-width: 991.5px) {
      grid-template-columns: minmax(30px, 3%) minmax(80px, 10%) minmax(190px, 25%) minmax(80px, 8%) minmax(100px, 12%) 1fr;

      td {
        padding: 15px !important;

        .file-option {
          gap: 30px !important;
        }
      }
    }

    @media only screen and (min-width: 767.5px) {
      display: grid;
      grid-template-columns: minmax(30px, 3%) minmax(80px, 10%) minmax(120px, 25%) minmax(80px, 8%) minmax(100px, 12%) 1fr;

      td {
        padding: 8px !important;
        display: flex;
        align-items: center;
        width: 100%;

        &:last-child {
          justify-content: flex-end;
        }

        .file-option {
          gap: 15px !important;
          padding-right: 90px;
          position: relative;

          .delete-file-btn {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }

          .tooltip-container,
          .download-file-btn {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
          }

          .tooltip-container {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      &:hover {
        td {
          background: #f7fcff !important;
          border-color: #def3ff !important;

          .tooltip-container {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }
      }
    }

    td {
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 767.5px) and (max-width: 991.5px) {
  .ant-table-content {
    tr {
      display: grid;
      grid-template-columns: minmax(30px, 3%) minmax(80px, 10%) 120px minmax(80px, 8%) minmax(100px, 12%) 1fr;

      .formate-info {
        .convert-to-btn {
          min-width: 100px;
        }
      }
    }
  }

  .file-type {
    gap: 10px;
  }
}

.ant-table-empty {
  .ant-table-content {
    tr {
      display: table !important;
      width: 100%;

      td {
        background-color: unset !important;
        border-radius: 0 !important;
        border: 0 !important;
      }

      .ant-empty-image {
        width: 120px !important;
        height: 120px !important;
        background-image: url('/assets/images/dashboard/emptyTable.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        svg {
          display: none !important;
        }
      }

      .ant-empty-description {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .your_documents_sec {
    .ant-table-thead {
      tr {
        display: grid;
        grid-template-columns: 1fr;

        th {
          &:first-child {
            text-align: left;

            &:after {
              content: 'Select All';
              padding-left: 10px;
              color: rgba(57, 57, 70, 0.5);
              font-weight: 500;
            }
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        border: 1px solid rgba(57, 57, 70, 0.1);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 40px 50px 120px 1fr;
        margin-top: 15px !important;
        position: relative;
        overflow: hidden;

        &.disabled-row {
          border-color: #ffb1b9 !important;
        }

        &.ant-table-row-selected {
          border-color: #def3ff;
          background-color: #f7fcff;
        }

        td {
          padding: 12px !important;
          width: 100%;
          border: 0 !important;
          padding: 0 !important;
          padding-top: 15px !important;
          margin-top: 0 !important;
          border-radius: unset !important;
          background-color: unset !important;
          border-color: unset !important;

          &:nth-child(1) {
            grid-column: 1/2;
            grid-row: 1/3;
          }

          &:nth-child(2) {
            grid-column: 2/3;
            grid-row: 1/3;
            align-content: center;
          }

          &:nth-child(3) {
            grid-column: 3/5;
          }

          &:nth-child(4) {
            position: relative;
            grid-column: 3/4;
            padding-top: 6px !important;
            font-size: 12px;
            font-weight: 400;

            &:before {
              content: 'Size :';
              padding-right: 1ch;
              color: rgba(57, 57, 70, 0.5);
              font-weight: 500;
            }
          }

          &:nth-child(5) {
            position: relative;
            grid-column: 3/5;
            padding-top: 6px !important;
            font-size: 12px;
            font-weight: 400;

            &:before {
              content: 'Last Modified :';
              padding-right: 1ch;
              color: rgba(57, 57, 70, 0.5);
              font-weight: 500;
            }
          }

          &:nth-child(6) {
            grid-column: 2/5;
            padding-bottom: 14px !important;
            position: unset !important;

            &>div {
              place-content: center start !important;
            }

            .more-option {
              position: absolute;
              top: 22px;
              right: 15px;
            }
          }
        }
      }
    }
  }

  .ant-table-cell:nth-child(6):empty {
    display: none !important;
  }
}

@media only screen and (max-width: 420px) {
  .your_documents_sec {
    .ant-table-tbody {
      tr {
        td {
          &:nth-child(5) {
            grid-column: 3 / 5;
            padding-top: 0px !important;
          }

          .file-option {
            gap: 15px 5px !important;

            .file-type {
              gap: 12px;
            }
          }
        }
      }
    }
  }

  .document-default-btn.convert-btn,
  .document-default-btn {
    font-size: 14px;
    gap: 10px;
    height: 40px;
    width: 110px;
    padding: 0 !important;
  }
}

.fileConvertStatus {
  background-color: #0076ce;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  color: white;
  font-weight: bold;
  padding: 8px;
  font-family: inter;

  @media only screen and (max-width: 767px) {
    text-align: left;
    padding: 10px 30px;
  }

  @media only screen and (max-width: 440px) {
    font-size: 14px;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;

  img {
    display: block !important;
  }
}

.tooltip-text {
  visibility: hidden;
  background-color: #181d20;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 150%;
  /* Position the tooltip above the image */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 134px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 8px;
  line-height: 1.2;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #181d20 transparent transparent transparent;
  }
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.retry_btn {
  background-color: #0076ce;
  height: 32px;
  width: 82px;
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;

  img {
    margin-left: 4px;
    filter: invert(100) brightness(100);
  }
}

@supports (div: has) {
  div:has(+ div > div > div .file-convert-second-modal) {
    display: none;
  }
}

.progress_status {
  .ant-progress-status-success,
  .ant-progress-status-exception {
    display: none;
  }
}

.download-icon {
  height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #bfdcf2;

  img {
    min-width: 15px;
    width: 15px;
    filter: brightness(0) invert(41%) sepia(42%) saturate(6976%) hue-rotate(187deg) brightness(88%) contrast(101%);
  }
}

.errorclass {
  padding: 8px 15px;
  border-radius: 4px;
  color: #de3838 !important;
  position: absolute;
  top: 0;
  right: 30px;
  margin-top: 8px;
  line-height: 1.1;

  &.error-style {
    border: 1px solid #ea9090;
    background: #FFE9E9;
    display: flex;
    align-items: center;

    svg {
      width: 17px;
      min-width: 17px;
      height: 17px;
      margin-right: 9px !important;
    }
  }
}