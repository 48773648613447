$color_1: #fff;
$color_2: #fff;
$color_3: #707375;
$color_4: #007bff;
$color_5: #2d2d2f;
$color_6: #808080;
$font-family_1: Inter;
$font-family_2: Roboto;
$background-color_1: var(--toolBodyClr);
$background-color_2: var(--elementClr);
$background-color_3: #fff;

/* ==============================================
== Tool Section Css Start
============================================== */
/*********************test-1************************/
/*******************end text*************************************/
/* width */
/* ==============================================
== Tool Section Css End
============================================== */
.tool-section {
  padding-bottom: 225px;
}

.tool-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .box-upper {
    padding: 15px;
    background-color: $background-color_1;
  }

  .box-footer {
    padding: 10px 15px;
    background-color: $background-color_3;
  }
}

.toll-box-inner {
  width: 25%;
  padding: 20px 20px;
}

.box-upper.image-tool {
  background-color: $background-color_1;
}

.box-upper.document-tool {
  background-color: $background-color_1;
}

.box-upper.vector-tool {
  background-color: $background-color_1;
}

.box-upper.video-tool {
  background-color: $background-color_1;
}

.box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.icon-box {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $background-color_2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-tool {
  padding: 4px 16px;
  color: $color_1;
  font-family: $font-family_1;
  font-size: 14px;
  font-weight: 500;
  background-color: $background-color_2;
  border-radius: 50px;
  cursor: pointer;
}

.box-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;

  h3 {
    color: $color_1;
    font-family: $font-family_2;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  p {
    color: $color_1;
    font-family: $font-family_2;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
  }

  img {
    cursor: pointer;
  }
}

ul.file-types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  height: 0;
  opacity: 0;
  overflow-y: hidden;
  visibility: hidden;

  li {
    max-width: 54px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--elementClr);
    background: var(--elementClr);
    text-align: center;
    font-family: $font-family_1;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 1px solid #ffffff;
    }
    a {
      color: $color_2;
      transition: all 0.3s ease-in-out;
      padding: 4px 2px;
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }
  }
}

ul.file-types.active {
  max-height: 120px;
  height: 100%;
  opacity: 1;
  overflow-y: auto;
  visibility: visible;
  transition: all 0.5s;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--elementClr);
    border-radius: 10px;
  }
}

.box-footer {
  p {
    border-radius: 7px;
    background: var(--footerClr);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color_3;
    font-family: $font-family_1;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;

    a {
      color: $color_4;
    }
  }
}

.tool-inner-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .slick-slide {
    padding: 20px 15px;
    max-width: 310px;
  }
}

ul.slick-dots {
  position: unset;

  li {
    width: 8px;
    height: 8px;
    transition: all 0.5s;
  }

  li.slick-active {
    width: 25px;
    height: 5px;
  }
}

.slick-dots {
  li {
    button {
      padding: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s;

      &:before {
        font-size: 0;
        width: 8px;
        height: 8px;
        background: rgba(255, 106, 72, 0.3);
        border-radius: 10px;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }

  li.slick-active {
    button {
      &:before {
        background: rgba(255, 106, 72, 0.7);
        width: 100%;
        height: 100%;
      }
    }
  }
}

.tool-about-box-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding-top: 80px !important;
  gap: 30px;
  @media only screen and (max-width: 766.5px) {
    padding-top: 100px !important;
  }
}

.box-icon {
  width: 80px;
  height: 80px;
  fill: #fff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media only screen and (max-width: 475px) {
    margin-bottom: 20px;
  }
}

.box-icon-container {
  width: 154px;
  height: 80px;
  border-radius: 45px;
  .img-container{
    height: 40px;
    width: 40px;
    fill: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #E3E3E3;
    border-radius: 50%;
    margin-left: -13px;
    background-color: #fff;
    img {
      height: 30px;
      width: 30px;
    }
  }
  .img-container:first-child {
    margin-left: 0;
  }
}

.tool-about-box {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  max-width: calc(33.33% - 20px);
  width: 100%;

  @media only screen and (max-width: 475px) {
    padding: 25px 20px;
  }
}

.about-box-content {
  h3 {
    color: $color_5;
    font-family: $font-family_1;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  p {
    color: $color_6;
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0px;

    @media only screen and (max-width: 475px) {
      margin-bottom: 15px;
    }
  }

  a {
    border-radius: 45px;
    border: 1px solid #e8e8e8;
    background: rgba(128, 128, 128, 0.1);
    width: 100%;
    padding: 10px 20px;
    color: $color_6;
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;
    &:hover {
      border-color: #bddff9;
      background-color: #eff7fd;
      padding: 10px 25px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .tool-about-box {
    max-width: calc(40% - 30px);
  }

  .tool-section {
    padding-bottom: 150px;
  }

  .tool-about-box-wrap {
    margin-bottom: 0px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1199px) {

  .how-work-section {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 991px) {
  .tool-about-box {
    max-width: calc(50% - 30px);
  }
}

@media screen and (max-width: 991px) {
  .tool-about-box {
    max-width: calc(50% - 30px);
  }

  .toll-box-inner {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .tool-inner-slider {
    .slick-slide {
      padding: 20px 8px;
    }
  }

  .tool-about-box {
    max-width: 100%;
    margin: 0;
  }

  .tool-about-box-wrap {
    padding: 20px 0;
    margin: 0;
  }

  .tool-section {
    padding: 50px 0;
    margin: 0;
    padding-bottom: 50px;
  }

  .about-box-content {
    h3 {
      font-size: 20px;
    }
  }

  .how-work-section {
    padding-bottom: 50px;
  }

  .file-box-content {
    h3 {
      margin-bottom: 15px;
    }
  }

  .toll-box-inner {
    width: 100%;
  }

  .toll-box-inner {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 575px) {
  .tool-about-box {
    max-width: 100%;
  }
}

// File Section New Css

.file-section.non-slider {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.file-section.slider {
  display: none;
}

@media only screen and (max-width: 991px) {
  .file-section.non-slider {
    display: none;
  }
}

.file-section {
  display: flex;
  gap: 30px;
  margin-top: 45px;
  position: relative;

  .card-wrapper {
    width: calc(25% - 22.5px);
    position: relative;
    min-height: 200px;
  }

  .slick-slide {
    padding: 8px;
  }

  .slick-dots {
    z-index: 3;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0px;
    width: 41px;
    height: 41px;
    background-repeat: no-repeat;
    left: 91%;
    top: -29px;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0px;
    width: 41px;
    height: 41px;
    background-repeat: no-repeat;
    top: 165px;
    left: -21px;
  }

  .file-card {
    width: 100% !important;
    border-radius: 10px;
    background: var(--color-white);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 5;

    .top-content {
      border-radius: 10px 10px 0px 0px;
      padding: 10px 15px 22px;
      display: flex;
      flex-flow: column;
      gap: 10px;

      .icon-tool {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tool-image {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .total-tool {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          padding: 4px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 24px;
        }
      }

      .description {
        display: flex;
        flex-flow: column;
        gap: 10px;

        .tool-name {
          color: #ffffff;
          font-size: 17px;
          font-weight: 700;
        }

        .tool-description {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          gap: 6px;
          width: 98%;

          .tool-text {
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .dropdown {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        height: 0;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        opacity: 0;

        .export-file {
          display: flex;
          width: 31%;
          text-align: center;

          .dropdown-menu {
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            padding: 4px 16px;
            border-radius: 4px;
            width: 150px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px white;
          border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb {
          background: white;
          border-radius: 50px;
        }
      }

      .dropdown.menu-visible {
        height: 110px;
        overflow-y: auto;
        opacity: 1;
      }
    }

    .bottom-content {
      padding: 10px 15px;

      .content {
        border-radius: 7px;
        background: #efedfd;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        label {
          color: #707375;
          font-size: 11px;
          font-weight: 400;
        }

        .text-info {
          color: #007bff;
          font-size: 11px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }

  .file-card.menu-visible {
    position: absolute;
    z-index: 10;
  }
}

@media only screen and (max-width: 991px) {
  .file-section.non-slider {
    display: none;
  }

  .file-section.slider {
    display: block;
    position: relative;
    z-index: 3;

    .dropdown {
      .export-file {
        width: 44% !important;
      }
    }
  }

  .file-section {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    .file-card.menu-visible {
      position: relative;

      .file-card {
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .file-section.slider {
    .dropdown {
      .export-file {
        width: 31% !important;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .file-section.slider {
    .dropdown {
      .export-file {
        width: 44% !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .file-section {
    .file-card {
      .top-content {
        .dropdown {
          #file-export {
            width: 44%;
          }
        }
      }
    }
  }
}
