@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$color_1: #fff;
$color_2: #777;
$color_3: #ffffff;
$color_4: #858585;
$color_5: #1664fa;
$font-family_1: 'GT Walsheim Pro';
$background-color_1: white;
$background-color_2: #000000;
$background-color_3: #4096ff;
$background-color_4: #d9d9d9;
$background-color_5: transparent;

.ant-modal.thankyou-info-wrap {
    .ant-modal-content {
        padding: 0px 20px 20px;
    }
}

.subscription-modal-title {
    text-align: center;

    span {
        color: $color_1;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0px 0px 10px 10px;
        background: #0076ce;
        padding: 13px 25px;
        width: 249px;
        display: inline-block;
        vertical-align: top;
    }
}

.subscription-modal-section {
    padding: 13px 0px 0px 0px;

    .wrap-top-modal {
        margin-top: 40px;

        .body-modal {
            .modal-thankyou-form {
                position: relative;
                margin-bottom: 10px;

                label {
                    .ant-form-item-required {
                        opacity: 0.6;
                        color: rgba(0, 0, 0, 0.88);
                        font-family: Inter;
                        font-size: 13.6px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.2px;
                    }
                }

                .ant-form-item {
                    .ant-col {
                        input {
                            width: 100%;
                            height: 49px;
                        }
                    }
                }
            }

            .submit-btn-modal {
                width: 100%;
                margin-top: 20px;
                justify-content: center !important;
                display: flex;

                .hl_cta_wrap {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    height: 100%;
                    padding: 9px 20px;
                    border: 1px solid #0076ce;
                    border-radius: 100px;
                    transition: 0.5s ease-in-out;
                    z-index: 1;
                    background-color: #0076ce;
                    color: #ffffff;
                    cursor: pointer;

                    .arrow-icon {
                        width: 20px;
                        height: 16px;
                        margin-left: 10px;
                    }
                }

                .cancel-btn-modal {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    height: 100%;
                    padding: 9px 20px;
                    border: 1px solid #e6f2fb;
                    border-radius: 100px;
                    transition: 0.5s ease-in-out;
                    z-index: 1;
                    background-color: #e6f2fb;
                    color: #0076ce;
                    cursor: pointer;

                    .arrow-icon {
                        width: 20px;
                        height: 16px;
                        margin-left: 10px;
                    }
                }
            }

            .description-modal {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 20px;
                font-size: 16px;
                margin-bottom: 30px;
            }

            .description-confirm {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 20px;
                font-size: 16px;
            }
        }
    }
}

.auth-divider {
    width: 100%;
    align-items: center;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;

    .line {
        flex: 1 1;
        height: 1px;
        background-color: $background-color_4;
    }
}

@media screen and (max-width: 576px) {
    .ant-modal.subscription-info-wrap {
        .ant-modal-content {
            padding: 0px 20px 15px;
            margin: 0px 20px;
        }
    }
}

@media screen and (max-width: 425px) {
    .ant-modal.subscription-info-wrap {
        .ant-modal-content {
            margin: 0px 10px;
        }
    }

    .subscription-modal-section {
        .wrap-top-modal {
            .body-modal {
                .submit-btn-modal {
                    .hl_cta_wrap {
                        font-size: 15px;
                    }
                }
            }

            .google-button-thankyou {
                .thankyou-bg-wrap {
                    font-size: 17px;
                }
            }
        }
    }

    .auth-divider {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .subscription-modal-title {
        span {
            font-size: 18px;
            width: auto;
        }
    }
}


.subscription-info-wrap {
    width: 472px !important;
    position: relative;

    .ant-modal-content {
        padding: 30px 30px;

        .subscription-modal-title {
            margin-top: -30px;
        }

        .subscription-modal-section {
            padding-top: 0px;
        }
    }
}

.subscription-modal-section {
    .wrap-top-modal {
        .body-modal {
            .description-modal {
                padding: 0px 0px;
                color: #696969;
                text-align: center;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5;
            }

            .submit-btn-modal {
                margin-top: 13px;
            }
        }
    }
}