$color_1: #fff;
$color_2: #777;
$color_3: #ffffff;
$color_4: #858585;
$color_5: #1664fa;
$font-family_1: 'GT Walsheim Pro';
$background-color_1: white;
$background-color_2: #000000;
$background-color_3: #4096ff;
$background-color_4: #d9d9d9;
$background-color_5: transparent;

.ant-modal.login-info-wrap {
  .ant-modal-content {
    padding: 0px 50px 38px;
  }
}
.login-modal-title {
  text-align: center;
  span {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0px 0px 10px 10px;
    background: #0076ce;
    padding: 15px 95px;
  }
}
.login-modal-section {
  padding: 0px;
  .wrap-top-modal {
    .google-button-login {
      padding: 0 20px;
      margin-bottom: 10px;
      display: none;
      align-items: center;
      justify-content: center;
      .login-bg-wrap {
        display: flex;
        justify-content: center;
        padding: 14px 15px;
        border-radius: 7px;
        height: 49px;
        align-items: flex-start;
        gap: 63px;
        transition:
          background-color 0.3s,
          box-shadow 0.3s;
        font-family: $font-family_1;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-repeat: no-repeat;
        background-position: 12px 14px;
        width: 100%;
        margin: 0px;
        cursor: pointer;
      }
    }
    .body-modal {
      .modal-login-form {
        position: relative;
        margin-bottom: 10px;
        .mb {
          margin-bottom: 0px;
          margin-left: 0px;
          width: 100%;
        }
        .ant-form-item {
          margin-bottom: 0;
          .ant-col {
            padding-bottom: 0;
            label{
              span{
                color: rgba(0, 0, 0, 0.40);
              }
            }
            input {
              width: 100%;
              height: 49px;
            }
          }
        }
      }
      .forget-pass-modal {
        text-align: center;
        margin-top: 20px;
        a {
          color: $color_4;
        }
      }
      .submit-btn-modal {
        width: 100%;
        margin-top: 30px;
        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          padding: 13px 20px;
          border: 1px solid #0076ce;
          border-radius: 7px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: #0076ce;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
.login-bg-wrap.login-with-google-btn {
  border: 1px solid rgba(209, 209, 215, 0.6);
  background: rgba(64, 150, 255, 0);
  color: $color_2;
  background-color: $background-color_1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
}
.login-bg-wrap.login-with-apple-btn {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M11.6305 2.73564C12.3078 2.01055 12.7659 1.00386 12.6405 0C11.6647 0.0348222 10.4821 0.572776 9.78175 1.29781C9.1539 1.93967 8.60421 2.96551 8.75091 3.94967C9.83972 4.02332 10.9521 3.45956 11.6305 2.73564ZM13.7085 9.66829C13.6877 7.41775 15.4903 6.33684 15.5701 6.28461C14.5567 4.75632 12.9784 4.5472 12.4163 4.52362C11.0724 4.38254 9.79589 5.33749 9.11358 5.33749C8.43405 5.33749 7.38297 4.54214 6.26835 4.5635C4.80303 4.58764 3.45287 5.44091 2.7003 6.79323C1.17813 9.51375 2.31016 13.5438 3.79344 15.7505C4.51853 16.8308 5.38186 18.0427 6.51556 17.9988C7.60993 17.955 8.02248 17.271 9.34283 17.271C10.6637 17.271 11.0336 17.9988 12.1892 17.9769C13.365 17.955 14.1092 16.8758 14.8281 15.7921C15.66 14.5393 16.0029 13.3263 16.0225 13.265C15.9973 13.2504 13.7326 12.3595 13.7085 9.66829Z' fill='white'/%3E%3C/svg%3E");
  background-color: $background-color_2;
  color: $color_3;
  border: 1px solid #000000;
  margin: 10px 0px;
}
.login-bg-wrap.login-with-facebook-btn {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg clip-path='url(%23clip0_3308_946)'%3E%3Cpath d='M18 9C18 13.4923 14.7087 17.2157 10.4062 17.8907V11.6016H12.5033L12.9023 9H10.4062V7.3118C10.4062 6.59988 10.755 5.90625 11.873 5.90625H13.0078V3.69141C13.0078 3.69141 11.9777 3.51562 10.993 3.51562C8.93742 3.51562 7.59375 4.76156 7.59375 7.01719V9H5.30859V11.6016H7.59375V17.8907C3.29133 17.2157 0 13.4923 0 9C0 4.02961 4.02961 0 9 0C13.9704 0 18 4.02961 18 9Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3308_946'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-color: #0076ce;
  color: $color_3;
  border: 1px solid #0076ce;
}
.auth-divider {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  .line {
    flex: 1 1;
    height: 1px;
    background-color: $background-color_4;
    display: none;
  }
  .or-text {
    display: none;
    color: rgba(0, 0, 0, 0.65);
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: 22px;
    margin-right: 22px;
    font-size: 13.6px;
  }
}

/* Responsive Styles */
@media screen and (max-width: 576px) {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .forget-pass-modal {
          margin-top: 20px;
        }
        .modal-login-form {
          .ant-form-item {
            .ant-col {
              input {
                width: 100%;
              }
            }
          }
        }
      }
      .google-button-login {
        padding: 0px;
      }
    }
  }
  .ant-modal.login-info-wrap {
    .ant-modal-content {
      padding: 0px 20px 15px;
      margin: 0px 20px;
    }
  }
}
@media screen and (max-width: 425px) {
  .ant-modal.login-info-wrap {
    .ant-modal-content {
      margin: 0px 10px;
    }
  }
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .submit-btn-modal {
          .hl_cta_wrap {
            font-size: 15px;
          }
        }
      }
      .google-button-login {
        .login-bg-wrap {
          font-size: 17px;
        }
      }
    }
  }
  .auth-divider {
    .or-text {
      font-size: 15px;
    }
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .login-modal-title {
    span {
      padding: 15px 60px;
    }
  }
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; 
}


.login-info-wrap {
	.ant-modal-content {
		margin: 0px auto !important;
		max-width: calc(100% - 16px) !important;
		width: 100% !important;
		padding: 15px 50px !important;
    @media only screen and (max-width: 767.5px) {
      padding: 15px 30px !important;
    }
    @media only screen and (max-width: 440.5px) {
      padding: 15px !important;
    }
	}
}
