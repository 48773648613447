.modal-listing-outer {
  margin: 25px 20px;
  .modal-title {
    padding-bottom: 15px;
    border-bottom: 1px solid #E8E8E8;
    color: rgba(57, 57, 70, 0.8);
    
  @media only screen and (max-width: 767px){
    padding-bottom: 9px;
    h2{
        font-size: 18px;
      }
    }
  }
  .checkbox-container {
    text-align: right;
    padding: 10px;
    display: flex;
    justify-content: end;
    .convert-to-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #A8A8A8;
      background-color: #FFFF;
    }
  }
  .modal_content-wrapper {
    width: 100%;
    border: 1px solid #00acea;
    border-radius: 10px;
    padding: 20px 15px;
    background-color: #eff7fd;
    margin-bottom: 10px;
    .convert-formate-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
      }
      .file-converting-main {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .file-list-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            max-width: 300px;
            -webkit-box-orient: vertical;
          }
        }
      }
      .file-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        .convert-to-main {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 15px;
          .convert-formate {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .convert-option button {
              display: flex;
              gap: 8px;
              justify-content: center;
              align-items: center;
              appearance: none;
              background: #fff;
              border: 1px solid rgba(0, 118, 206, 0.2509803922);
              border-radius: 5px;
              color: #393946;
              cursor: pointer;
              font-size: 13px;
              font-weight: 500;
              min-width: 129px;
              outline: #ffdead;
              padding: 8px 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .upload_btn-modal {
    span {
      color: #fff;
      font-family: 'Roboto';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      border-radius: 42px;
      background: #0076ce;
      padding: 11px 30px;
      border: 1px solid #0076ce;
      transition: all 0.4s ease 0s;
      margin-top: 15px;
      display: inline-block;
      &:hover {
        background: transparent;
        color: #0076ce;
      }
      img{
        width: 20px;
        margin-left: 10px;
      }
      &:not(:hover){
        img{
        filter: invert(100) brightness(100);
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .ant-modal-body{
    padding: 0;
    .modal-listing-outer{
      margin: 10px 5px;
      .modal_content-wrapper{
        position: relative;
        .convert-formate-inner{
          position: unset;

          .file-converting-main{
            .file-list-inner{
              align-items: self-start;
              justify-content: space-around;
              gap: 6px;
              flex-direction: column;

              span{
                &:first-of-type{
                  position: relative;
                  font-size: 12px;
                  font-weight: 400;
                  &:before{
                    content: "Size :";
                    padding-right: 1ch;
                    color: rgba(57, 57, 70, 0.5);
                    font-weight: 500;
                  }
                }
              }
            }
          }
          .file-actions{
            justify-content: flex-start;

            .convert-close{
              position: absolute;
              top: 18px;
              right: 18px;
            }
          }
        }
      }
    }
  }
}

.download-all-icon{
  background-color: #0076CE;
  border: 1px solid #0076CE;
  border-radius: 45px;
  padding: 10px 30px;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin: 25px auto 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img{
    min-width: 16px;
    width: 16px;
    height: 16px;
    filter: brightness(100);
  }
}