@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
$color_1: #393946;
$color_2: #0076ce;
$color_3: #00acea;
$font-family_1: 'Inter', sans-serif;
$font-family_2: Roboto;
$background-color_1: rgba(0, 172, 234, 0.1);

.banner-bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.file-icons-inner {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    height: 24px !important;
    width: 24px !important;
  }
  span {
    font-size: 18px;
    text-transform: uppercase;
    color: rgba(57, 57, 70, .6);
  }
}

.banner-section {
  padding: 100px 0px 144px;
}

.banner-content {
  text-align: center;
  max-width: 885px;
  margin: 0 auto;
  .dropdown-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
    flex-wrap: wrap;

    @media only screen and (max-width: 380px) {
      gap: 12px;
    }

    .drop-button {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      align-items: center;
      font-size: 60px;
      line-height: 1.03;
      color: #393946;
      font-family: 'Inter', sans-serif;
      font-weight: 900;
      padding: 14px 19px;
      border-radius: 10px;
      border: 1px solid rgba(0, 118, 206, 0.25);
      background: #ffffff;
      @media only screen and (max-width: 991.5px) {
        font-size: 45px;
      }
      @media only screen and (max-width: 767.5px) {
        font-size: 40px;
        padding: 10px;
      }
      @media only screen and (max-width: 440px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 340px) {
        font-size: 24px;
      }
      img {
        height: 11px;
        width: 22px;
      }
    }
    span {
      font-size: 60px;
      line-height: 1.03;
      color: #393946;
      font-family: 'Inter', sans-serif;
      font-weight: 900;
      @media only screen and (max-width: 991.5px) {
        font-size: 45px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 440px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 340px) {
        font-size: 24px;
      }
    }
  }

  h1 {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 65px;
    font-weight: 900;
    margin-bottom: 25px;
  }

  p {
    color: $color_1;
    font-family: $font-family_2;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
  }
}

.drag-drop-inner {
  border-radius: 10px;
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.083),
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: transparent;
  height: 100%;
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
}

.drag-drop-element {
  input[type='file'] {
    display: none;
  }

  max-width: 1010px;
  width: 100%;
  height: 280px;
  border-radius: 10px;
  background: transparent;
  margin: 50px auto 0;
  position: relative;

  &.no-pointer {
    cursor: default !important;

    label {
      cursor: default !important;
    }
  }

  label {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 255px;
    justify-content: center;
    flex-direction: column;
    color: #808080;
    text-align: center;
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    &.disable-button {
      cursor: default !important;
      color: #999;
    }

    &:before {
      content: '';
      width: 50px;
      height: 50px;
      background-image: url('../../../../assets/images/home/cloud-upload-icon.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: 45px;
      margin-bottom: 20px;
    }

    &:after {
      content: 'Browse File';
      padding: 10px 50px;
      margin-top: 20px;
      border-radius: 42px;
      border: 1px dashed rgba(0, 118, 206, 0.4);
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 52px;
      background-color: #0076ce;
      position: relative;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #fff;
        background-color: #005A9D;
        border-color: rgba(0, 90, 157, 0.8);
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    inset: 15px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 118, 206, 0.4);
    background: rgba(217, 217, 217, 0.01);
  }
}

.banner-device-element {
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 15px 15px;
  gap: 15px;

  @media only screen and (max-width: 475px) {
    padding: 0 15px 30px;
    gap: 11px;
  }

  .device-icon {
    width: 25%;
    padding-right: 0px;

    .device-icon-inner {
      height: 60px;
      border-radius: 5px;
      border: 1px solid #eaf0fb;
      background: #eff7fd;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      @media only screen and (max-width: 475px) {
        .device-icon-inner img {
          width: 100%;
          max-width: 26px;
        }
      }
    }
  }

  span {
    color: #6B6B6B;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: all 0.3s ease-out;
    @media only screen and (max-width: 850px) {
      font-size: 13px;
    }
  }
}

.file-convert-list-main {
  padding: 0 15px;

  .your-file span {
    color: #61616b;
    font-family: $font-family_1;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-top: 30px;
    display: inline-block;
    width: 100%;
    line-height: normal;
    @media only screen and (max-width: 767px) {
      padding: 15px 0 !important;
    }
  }

  .single_line {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 118, 206, 0.16);

    @media only screen and (max-width: 767px) {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    p {
      color: #f00;
      font-size: 12px;
      font-family: 'Inter', sans-serif;
    }
  }

  .file-converting-main {
    display: flex !important;
    align-items: center;
    justify-content: start;
    padding-bottom: 12px;
    width: 100%;

    .file-convert-img {
      flex-shrink: 0;
      width: 60px;
      height: auto;
      display: flex;
      align-items: center;
      svg{
        width: 35px;
        height: 35px;
      }
      span{
        margin-left: 2px;
        color: #393946;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .file-list-inner {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 45%;
      width: 100%;
      @media only screen and (max-width: 991px) {
        max-width: 280px;
      }

      p {
        color: #393946;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 3px;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
        margin: 0;
      }

      span {
        color: #a7afb5;
        font-family: $font-family_1;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .errorclass {
      padding: 8px 15px;
      border-radius: 4px;
      color: #de3838 !important;
      position: absolute;
      top: 0;
      right: 30px;
      margin-top: 8px;
      line-height: 1.1;

      &.error-style {
        border: 1px solid #ea9090;
        background: #FFE9E9;
        display: flex;
        align-items: center;
        
        svg{
          width: 17px;
          min-width: 17px;
          height: 17px;
          margin-right: 9px !important;
        }
      }
    }
  }
}
span.errorclass:empty {
  display: none;
}
@media only screen and (max-width: 767px) {
  .file-convert-list-main{
    .file-convert-list-main{
    padding: 15px 0 0;
    }
    .file-converting-main{
      padding-bottom: 0;

      .file-list-inner{
        max-width: calc(100% - 60px);

        p{
          font-size: 14px;
        }
      }
      .errorclass{
        position: unset;
        font-size: 14px;
        margin-top: 10px;        
        padding: 8px 10px;
      }
    }
    .file-actions{
      .convert-to-main{
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .dropdown-error{
          &.has-error{
            position: unset;
            padding: 8px 10px;

            span{
              font-size: 14px;
            }
          }
        }
      }
      .convert-close{
        width: fit-content;
        position: absolute;
        right: 0;
        top: 20px;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .file-convert-list-main {
    .file-actions{ 
      .convert-to-main {
        .dropdown-error{ 
          &.has-error span {
            font-size: 12px;
          }
        }
      }
    }
    .file-converting-main {
      .errorclass{
        font-size: 12px;
      }
    }
  }
}

.convert-formate-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }

  .convert-formate {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .download-icon{
      height: 32px;
      width: 32px;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .convert-to-main {
    display: flex;
    align-items: center;
    column-gap: 31px;

    .convert-close img {
      cursor: pointer;
      width: 12px;
      min-width: 12px;

      @media only screen and (max-width: 767px) {
        position: absolute;
        top: 50px;
        right: 7px;
        transform: translate(50%, -50%);
        bottom: 50%;
      }
    }

    .convert-option div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .dropdown-error {
    padding: 8px 15px;
    border-radius: 4px;
    justify-content: start;
    position: absolute;
    width: fit-content;
    top: -33px;
    right: 30px;

    span {
      color: #a7afb5;
      font-weight: 400;
    }

    &.has-error {
      border: 1px solid #ea9090;
      background: #FFE9E9;
      display: flex;
      align-items: center;

      svg{
        min-width: 17px;
        width: 17px;
        height: 17px;
        margin-right: 9px !important;
      }

      span {
        color: #de3838;
        font-weight: 500;
      }
      @media only screen and (max-width: 767px) {
        top: 55px;
      }
    }

    &.conversion-error {
      border: 1px solid #ea9090;
      background: #e6b8b8;
      top: -35px;
      padding: 8px 5px;
      color: #de3838;
      font-weight: 500;

      @media only screen and (max-width: 767px) {
        top: 40px;
      }
    }
  }
}

.convert-option div {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 118, 206, 0.25);
  background: #fff;
  width: 100%;
  min-width: 129px;
  padding: 8px 10px;
  outline: navajowhite;
  color: #393946;
  appearance: none;
  font-family: $font-family_1;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.convert-formate span {
  font-family: $font-family_1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
}

.convert-img img {
  width: 18px;
  min-width: 18px;
  height: 18px;
  display: block;
}

.convert-btn button {
  border-radius: 45px;
  border: 1px solid rgba(0, 118, 206, 0.2);
  background: #0076ce;
  padding: 10px 40px;
  color: #fff;
  font-family: $font-family_1;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  text-transform: capitalize;
}

.convert-btn {
  padding-top: 15px;
  padding-bottom: 30px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  .banner-bg-img {
    display: none;
  }

  .banner-section {
    background-image: url('../../../../assets/images/home/mobile-hero-banner-pattern.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 0px 60px;

    @media only screen and (max-width: 475px) {
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .banner-content {
    h1 {
      font-size: 36px;
    }

    p {
      font-size: 16px;
    }
  }

  .banner-device-element {
    span {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .banner-device-element {
    .device-icon {
      &:not(:first-child) {
        padding-left: 0px;
      }

      &:not(:last-child) {
        padding-right: 0px;
      }

      .device-icon-inner {
        height: 48px;
      }
    }

    .drag-drop-element {
      margin-top: 40px;

      label {
        height: 280px;
        font-size: 14px;

        &:before {
          margin-top: 15px;
          margin-bottom: 20px;
        }

        &:after {
          font-size: 14px;
        }
      }
    }
  }
}

.custom-select-popup {
  position: relative;
}

.popup {
  position: absolute;
  top: 55px;
  transform: translate(-90%, 0);
  background: white;
  border: 1px solid rgba(0, 118, 206, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px 18px 15px;
  z-index: 1000;
  border-radius: 10px;

  @media only screen and (min-width: 767.5px) and (max-width: 1440px) {
    top: -22px;
    right: 0px !important;
    transform: unset;
  }
  @media only screen and (min-width: 767.5px){
    max-height: 500px;
    overflow: auto;

    &::-webkit-scrollbar{
      width: 6px;
      border-radius: 50px;
      background-color: rgba(0, 118, 206, 5%);
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 50px;
      background-color: #0076ce;
    }

  }

  @media only screen and (max-width: 767px) {
    transform: translate(0, 0);
    position: fixed;
    inset: 0;
    width: 100%;
    max-width: 100% !important;
    border: 0;
    border-radius: 0;
    overflow: scroll;
  }
  
  .close-btn {
    text-align: right;
    padding-bottom: 14px;
    display: none;

    @media only screen and (max-width: 767.5px) {
      display: block;
    }
  }

  .field-item.selected span {
    color: #0076ce;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .field-item {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 118, 206, 0.2);
      padding-bottom: 13px;
      margin-bottom: 10px;
      gap: 6px;
    }
  }

  .field-item span {
    text-transform: capitalize;
    color: #393946;
    font-size: 14px;
    font-weight: 500;
  }

  .field-item.selected {
    border-bottom: 1px solid #0076ce;
    padding-bottom: 13px;
    margin-bottom: 10px;
  }

  .popup-row input {
    border: 0;
    color: rgba(57, 57, 70, 0.7);
    padding-left: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    padding: 6px 12px;

    .ant-input-search .ant-input:focus {
      border: 0 !important;
      border-radius: 0;
      box-shadow: unset;
    }
  }
}

.popup-row {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 118, 206, 0.2);
    margin-bottom: 10px;
  }

  .ant-btn-default {
    border: 0;
    justify-content: right;
    box-shadow: unset;
  }

  .ant-input-search-button:hover {
    color: unset !important;
  }
  input{
    box-shadow: none !important;
  }
  button[type="button"]:focus,
  button[type="button"]:hover,
  button[type="button"]{
    box-shadow: none !important;
    justify-content: center !important;
    outline: none !important;
    border: none !important;

    & > div{
      display: none;
    }
  }
  
}
@supports (selector(:has(*))){
  .popup-row:has(input:focus) {
    border: 1px solid #0076ce33;
    border-radius: 4px;
  }
}

.search-input {
  width: 100%;
  padding: 5px;
}

.columns {
  display: flex;
  gap: 12px;
  align-items: start;
  justify-content: start;
}

.column {
  &.second-column ul {
    row-gap: 10px !important;
    column-gap: 7px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      color: #393946;
      text-align: center;
      text-transform: uppercase;
      font-family: $font-family_1;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 7px 0 !important;
      border-radius: 2px !important;
      width: 100%;
      min-width: 90px;
      max-width: fit-content;
      padding: 5px 0;
      border: 1px solid rgba(0, 118, 206, 0.3);
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        min-width: 100%;
        word-wrap: break-word;
      }
    }
  }
}

.first-column {
  width: 100%;
  min-width: 123px;
  max-width: fit-content;

  @media only screen and (max-width: 475px) {
    min-width: 0;
    width: unset;
  }
}

.second-column {
  width: 75%;
  padding-left: 0;

  @media only screen and (max-width: 410px) {
    width: 60%;
  }
}

// ****** drop-box-move-section *******

.drop-box-move-section {
  .drag-drop-element {
    background-color: transparent;
    height: 440px;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (max-width: 420px){
      height: auto;
      padding-bottom: 30px;
    }

    .drop-box-move-inner {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      z-index: 999;
      max-width: 97%;
      width: 100%;
      @media only screen and (max-width: 1024px) {
        max-width: 80%;
      }
      @media only screen and (max-width: 767px) {
        max-width: calc(100% - 60px);
      }
      .box-move-head-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;        
        @media only screen and (max-width: 575px) {
          gap:10px;
        }

        .convert-box-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          img {
            max-width: 26px;
            width: 100%;
            height: 100%;
            @media only screen and (max-width: 575px) {
              max-width: 20px;
            }
          }
          .convert_icon {
            max-width: 14px;
            width: 100%;
            height: 100%;
          }

          span {
            color: #000;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media only screen and (max-width: 767px) {
              font-size: 10px;
            }
          }
        }
      }

      .drop-box-convert-icon {
        img {
          max-width: 249.2px;
          width: 100%;
        }
      }

      .drop-box-convert-content {
        margin-top: 20px;
        max-width: 820px;
        width: 100%;
        h3 {
          color: #393946;
          font-family: Inter;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          margin-bottom: 10px;
        }

        p {
          color: #393946;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .drop-box-progress-bar {
          margin-top: 20px;

          .progress-bar-inner {
            .progress-count {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              @media only screen and (max-width: 575px) {
                margin-bottom: 0;
              }
              @media only screen and (max-width: 420px) {
                flex-direction: column-reverse;
                gap: 16px;
              }
              h5{
                font-size: 16px;
                font-weight: 600;
                @media only screen and (max-width: 991px) {
                  font-size: 14px;
                }
                @media only screen and (max-width: 575px) {
                  font-size: 12px;
                }
                @media only screen and (max-width: 420px) {
                  width: 100%;
                  text-align: left;
                }
              }
            }
            .ant-progress-outer {
              @media only screen and (max-width: 768px) {
                width: 100% !important;
              }
            }

            .ant-progress-inner {
              background-color: #dbf0ff;

              .ant-progress-bg.ant-progress-bg-outer {
                background-color: #0076ce;
              }
            }
            .secure-section {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin-top: 15px;
              .ssi-side {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12px;
                span {
                  font-size: 12px;
                  color: #393946;
                }
              }
              .arrow {
                color: #393946;
              }
            }
          }
        }
      }
    }
    &:before {
      position: absolute;
      content: '';
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      inset: 15px;
      border-radius: 10px;
      border: 1px dashed rgba(0, 118, 206, 0.4);
      background: #f0f9ff;
    }
  }

  @media only screen and (max-width: 1200px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .drop-box-convert-icon {
          img {
            max-width: 230px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .drop-box-convert-icon {
          img {
            max-width: 200px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 26px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .drop-box-convert-icon {
          img {
            max-width: 150px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 18px;
          }

          p {
            padding: 0px 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 475px) {
    .drag-drop-element {
     .drop-box-move-inner {
       .drop-box-convert-icon {
          img {
            max-width: 150px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// Animation Css

.banner-device-element {
  .device-icon {
    .device-icon-inner {
      &:hover {
        span {
          transform: scale(1.2);
        }
        img {
          transform: scale(1.1);
        }
      }
      img {
        transition: all 0.3s ease-out;
        max-width: 25px;
        height: 100%;
      }
    }
  }
}

.banner-bg-img {
  .animation_wrapper {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    animation: actionicon 1s infinite alternate;
  }
  .animation_banner_img1 {
    text-align: left;
  }
  .animation_banner_img4 {
    position: absolute;
    top: 15%;
    bottom: auto;
    right: 5%;
    left: auto;
    text-align: right;
  }
}

@keyframes actionicon {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

// Files Upload SCSS

.convert-close {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  line-height: 0px;
  button.close_imgfield {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    line-height: 0px;
    outline: unset;
    border: unset;
    background-color: transparent;
  }
}
.wpb_content-wrapper {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 118, 206, 0.16);
  margin-bottom: 15px;
  .wpb_files__progress {
    margin-top: 10px;
    .ant-progress-outer {
      .ant-progress-inner {
        background-color: rgb(0 118 206 / 7%);
        border: 1px solid rgb(0 118 206 / 14%);
      }
    }
    .ant-progress-inner {
      .ant-progress-bg {
        background-color: #0076ce;
      }
    }
    span.ant-progress-text {
      font-weight: 600;
    }
  }
}

.wpb_convert-btn {
  width: 100%;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 30px;

  button {
    background: #0076ce;
    border: 1px solid #0076ce33;
    border-radius: 45px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 40px;
    text-transform: capitalize;

    &.disable {
      background: #1084e4; /* Light blue background for disabled state */
      cursor: not-allowed; /* Change cursor to not-allowed */
      border: 1px solid #2788d233; /* Matching border for light blue */
    }
  }
}

@media only screen and (max-width: 767px) {
  .file-convert-list-main {
    .convert-formate-main {
      .convert-to-main {
        width: 100%;
        justify-content: space-between;
        .convert-close {
          img {
            position: unset;
            transform: unset;
          }
        }
      }
    }
  }
  .convert-close {
    button.close_imgfield {
      text-align: right;
    }
  }
  .file-actions {
    width: 100%;
  }
}

.progress-animated .ant-progress-bg {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: progress-stripes 1s linear infinite;
}

@keyframes progress-stripes {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}
.picker-dialog-bg {
  opacity: 0 !important;
  z-index: -1 !important;
}

.device-icon-inner:focus {
  span {
    transform: scale(1.2);
  }
  img {
    transform: scale(1.1);
  }
}


.file-convert-second-modal{  
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
  overflow: auto;
  padding-bottom: 0;

  &::-webkit-scrollbar{
    width: 8px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb{
    background-color:rgba(0, 0, 0, .3);
    border-radius: 50px;
  }
  .ant-modal-content{
    .file-convert-list-main{
      padding: 15px;
      .your-file{
        span{
          padding-top: 0;
        }
      }
      .file-convert-list-main{
        padding: 0;
        .wpb_content-wrapper{
          padding: 20px 15px;
          border: 1px solid rgba(0, 172, 234, .3);
          background: #EFF7FD;
          border-radius: 10px;
          margin-bottom: 10px;
          .convert-formate-main{ 
            row-gap: 10px !important;
            .file-converting-main{
              padding-bottom: 0;
              .file-convert-img{
                width: fit-content !important;
                margin-right: 20px;
                span{
                  display: none;
                }
              }
            }
            .file-actions{
              .convert-formate {
                display: flex    ;
                align-items: center;
                column-gap: 10px;
                width: 150px;
                justify-content: flex-end;

                & > img{
                  margin-right: auto;
                }

                .convert-img{
                  svg{
                    width: 30px !important;
                    height: 30px !important;
                  }
                }
            
                .download-icon{
                  height: 32px;
                  width: 32px;
                  min-width: 32px;
                  padding: 0 !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  border: 1px solid #BFDCF2;
            
                  img{
                    min-width: 15px;
                    width: 15px;
                    filter: brightness(0) invert(41%) sepia(42%) saturate(6976%) hue-rotate(187deg) brightness(88%) contrast(101%);
                  }
                }
              }
            }
          }          
          .ant-progress-status-success,
          .ant-progress-status-exception{
            display: none;
          }
        }
      }
      .download-all-icon{
        background-color: #0076CE;
        border: 1px solid #0076CE;
        border-radius: 45px;
        padding: 10px 30px;
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        text-transform: capitalize;
        margin: 25px auto 0;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        img{
          min-width: 16px;
          width: 16px;
          height: 16px;
          filter: brightness(100);
        }
      }
    }
  }
}

@media only screen and (max-width: 767.5px){
  .file-convert-second-modal{
    .ant-modal-content{
      .file-convert-list-main{
        .your-file{
          margin-bottom: 15px;
        }
        .file-convert-list-main{
          .file-converting-main{
            .file-convert-img{
              width: 70px;
              height: 30px;
              svg{
                height: 100% !important;
                width: 30px !important;
                object-fit: contain;
              }
            }
            .file-list-inner{
              max-width: calc(100% - 70px);
            }
          }
          .wpb_content-wrapper{
            padding: 15px;
            .convert-formate-main{
              flex-direction: column !important;
              position: relative;

              @supports (selector(:has(*))){
                &:has(+ .ant-progress-status-active){
                  flex-direction: row !important;

                  .file-actions{
                    max-width: fit-content !important;
                    width: fit-content !important;
                  }
                }
              }
              .file-actions{
                max-width: calc(100% - 50px);
                width: 100% !important;
                margin-left: auto;
                .convert-formate{
                  width: fit-content;

                  .download-icon{
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}