
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$color_1: #fff;
$color_3: #ffffff;
$color_5: #1664fa;
$background-color_1: #d9d9d9;
.ant-modal.url-info-wrap {
  .ant-modal-content {
    padding: 0px 20px 20px;
  }
}

.url-modal-title {
  text-align: center;

  span {
    color: $color_1;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0px 0px 10px 10px;
    background: #0076ce;
    padding: 13px 72px;
    text-align: center;
  }
}

.url-modal-section {
  padding: 13px 0px 0px 0px;

  .wrap-top-modal {
    margin-top: 40px;

    .body-modal {
      .modal-url-form {
        position: relative;
        margin-bottom: 10px;

        label {
          .ant-form-item-required {
            opacity: 0.6;
            color: rgba(0, 0, 0, 0.88);
            font-family: Inter;
            font-size: 13.6px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.2px;
          }
        }

        .ant-form-item {
          .ant-col {
            input {
              width: 100%;
              height: 49px;
            }
          }
        }
      }

      .submit-btn-modal {
        width: 100%;
        margin-top: 20px;
        justify-content: center !important;
        display: flex;

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          height: 100%;
          padding: 9px 20px;
          border: 1px solid #0076ce;
          border-radius: 25px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: #0076ce;
          color: $color_3;
          cursor: pointer;

          .arrow-icon {
            width: 20px;
            height: 16px;
            margin-left: 10px;
          }
        }
      }

      .description-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        font-size: 16px;
      }
    }
  }
}

.auth-divider {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;

  .line {
    flex: 1 1;
    height: 1px;
    background-color: $background-color_1;
  }
}

@media screen and (max-width: 576px) {
  .ant-modal.url-info-wrap {
    .ant-modal-content {
      padding: 0px 20px 15px;
      margin: 0px 20px;
    }
  }
}

@media screen and (max-width: 425px) {
  .ant-modal.url-info-wrap {
    .ant-modal-content {
      margin: 0px 10px;

      button[aria-label="Close"]{
        width: 24px;
        height: 24px;
        top: 10px;
        right: 10px;
      }
    }

  }

  .url-modal-section {
    .wrap-top-modal {
      .body-modal {
        .submit-btn-modal {
          .hl_cta_wrap {
            font-size: 15px;
          }
        }
      }

      .google-button-url {
        .url-bg-wrap {
          font-size: 17px;
        }
      }
    }
  }

  .auth-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .url-modal-title {
    span {
      font-size: 18px;
      padding: 13px 24px;
    }
  }
}

.modal-url-form {
  label {
    display: block;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: rgba(103, 103, 103, 0.80);
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 45px;
    padding: 8px 12px;
    font-family: Inter;
    font-size: 14px;
    color: #000;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background-color: $color_1;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;

    &::placeholder {
      color: #aaa;
      font-size: 14px;
    }

    &:focus {
      border-color: #0076ce;
      outline: none;
    }
  }

  .error-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
  }
}
